import React, { Fragment, useEffect, useState } from "react";
import { server_url } from "../../environmentVariables/env";
import axios from "axios";
import { beautifyDate, beautifyDateTime } from "../../helpers/beautifyDate";
import { beautifyComments } from "../../helpers/beautifyComments";
// import { useUser } from "../../context/User/UserContext";
import qs from "qs"
// import cogoToast from "cogo-toast";
import { useMedusa } from "../../context/medusa/MedusaContext";
import Spinner from "../../components/spinner/Spinner";
import { useNavigate } from "react-router-dom";

const initialStateNewComment = {
    type: "blog",
    blog_id: "",
    parent_comment_id: "",
    author: "",
    author_email: "",
    content: "",
}

const BlogCommentDynamic = ({ blog }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState(initialStateNewComment);
    const [errorNewComment, setError] = useState([]);

    // console.log(blog);
    const navigate = useNavigate();

    const { user } = useMedusa();

    useEffect(() => {
        if (!user) return;
        setNewComment({
            ...newComment,
            author: user.first_name,
            author_email: user.email,
        })
    }, [user])

    useEffect(() => {
        if (!blog) return;

        fetchComments();
    }, [blog]);

    const fetchComments = async () => {
        try {
            const stringifiedQuery = qs.stringify({
                where: {
                    blog_id: {
                        equals: blog.id,
                    },
                },
            }, { addQueryPrefix: true });

            await axios.get(`${server_url}/api/comments/${stringifiedQuery}`)
                .then(response => {
                    // console.log(response.data.docs)
                    return response.data.docs
                })
                .then(data => {
                    // const filteredComments = data
                    // .filter(comment => comment.blog_id === blog?.id)
                    setComments(() => beautifyComments(data))
                })
                .catch(err => err)
        } catch (e) {
            console.log(e);
        }
    }

    const validateCreateNewComment = values => {
        const errors = [];
        if (!user?.email) {
            errors.push("Before adding a comment try logging in or create new account")
        }
        if (!values?.content) {
            errors.push("Add content to the comment")
        }
        return errors;
    }

    const createComment = async () => {
        const errors = validateCreateNewComment(newComment);
        if (errors.length) {
            setError(errors);
        }
        try {
            setIsLoading(true);
            const _values = {
                ...newComment,
                blog_id: blog.id
            }
            await axios.post(`${server_url}/api/comments`, _values)
                .then(response => response.data.doc)
                // .then(data => console.log(data))
                .catch((err) => {
                    return err
                })

            fetchComments()
        } catch (e) {
            setError(e.message)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Fragment>
            {comments.length > 0 &&
                <CommentSection
                    comments={comments}
                    resetComments={fetchComments}
                />
            }

            <div className="blog-reply-wrapper mt-50">
                <h4 className="blog-dec-title">post a comment</h4>
                <form className="blog-form">
                    <div className="row">
                        {/* <div className="col-md-6">
                            <div className="leave-form">
                                <input type="text" placeholder="Full Name" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="leave-form">
                                <input type="email" placeholder="Email Address " />
                            </div>
                        </div> */}
                        <div className="col-md-12">
                            <div className="text-leave">
                                <textarea
                                    placeholder="Comment..."
                                    defaultValue={""}
                                    value={newComment?.content}
                                    onChange={(e) => {
                                        setNewComment({
                                            ...newComment,
                                            content: e.target.value
                                        })
                                    }}
                                />
                                <button
                                    style={{ width: "150px" }}
                                    className="btn-primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (user) {
                                            createComment();
                                        } else {
                                            navigate(`/login-register/login`);   
                                        }
                                    }}
                                >
                                    {isLoading ? <Spinner color="darkgray" /> : "POST COMMENT"}
                                </button>
                                <p className="error-text">
                                    {errorNewComment.length > 0 && errorNewComment[0]}
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

function CommentSection({ comments, resetComments }) {
    return (
        <div className="blog-comment-wrapper mt-55">
            {comments && comments.map(comment => (
                <Comment key={comment.id} comment={comment} resetComments={resetComments} />
            ))}
        </div>
    );
}

function Comment({ comment, resetComments }) {
    const {
        author,
        author_email,
        content,
        createdAt,
        associatedComments
    } = comment;
    const _createdAt = beautifyDateTime(createdAt);
    const _associatedComments = associatedComments
        .sort((a, b) => {
            let date_A = new Date(a.createdAt);
            let date_B = new Date(b.createdAt);
            if (date_A < date_B) {
                return -1;
            } else if (date_A > date_B) {
                return 1;
            } else {
                return 0;
            }
        });

    const { user } = useMedusa();

    // Edit Comment
    const [editMode, setEditMode] = useState(false);
    const [editValues, setEditValues] = useState({
        ...initialStateNewComment,
        ...comment,
    })
    const [errorsEditValues, setErrorsEditValues] = useState([]);

    // Reply Comment
    const [replyMode, setReplyMode] = useState(false);
    const [replyValues, setReplyValues] = useState({
        ...initialStateNewComment,
        parent_comment_id: comment.id,
    })
    const [errorsReplyValues, setErrorsReplyValues] = useState([]);

    useEffect(() => {
        if (!user) return;
        setEditValues({
            ...editValues,
            author: user.first_name,
            author_email: user.email
        })
        setReplyValues({
            ...replyValues,
            author: user.first_name,
            author_email: user.email
        })
    }, [user]);

    const isMyComment = user?.email === comment?.author_email

    // console.log(createdAt)
    // const _createdAt = beautifyDateTime(createdAt);

    const validateUpdateComment = values => {
        const errors = [];
        if (!user?.email) {
            errors.push("Before editing the comment try logging in or create new account")
        }
        if (!values?.content) {
            errors.push("Add content to the comment")
        }
        return errors;
    }

    const updateComment = async () => {
        if (!isMyComment) return;
        const errors = validateUpdateComment(editValues);
        if (errors.length > 0) {
            setErrorsEditValues(errors);
            return;
        }

        const _values = {
            // id: comment.id,
            // type: "blog",
            // blog_id: comment.blog_id,
            // parent_comment_id: comment.parent_comment_id,
            // author: user.first_name,
            // author_email: user.email,
            content: editValues.content,
        }
        // console.log(_values);
        const stringifiedQuery = qs.stringify({
            where: {
                id: {
                    equals: comment.id,
                },
            },
        }, { addQueryPrefix: true });
        try {
            await axios.patch(`${server_url}/api/comments/${stringifiedQuery}`, _values)
                .then(response => response.data.docs)
                .then(data => {
                    console.log(data);
                })
                .catch(err => err)

            await resetComments()
        } catch (e) {
            console.error(e.message)
        }
    }

    const validateReplyComment = values => {
        const errors = [];
        if (!user?.email) {
            errors.push("Before creating the comment try logging in or create new account")
        }
        if (!values?.content) {
            errors.push("Add content to the comment")
        }
        return errors;
    }

    const createNewReplyComment = async () => {
        // console.log(replyValues);
        const errors = validateReplyComment(replyValues);
        if (errors.length > 0) {
            setErrorsReplyValues(errors);
            return;
        }

        const _values = {
            ...replyValues,
            blog_id: comment.blog_id,
            parent_comment_id: comment.parent_comment_id ? comment.parent_comment_id : comment.id
        }

        try {
            await axios.post(`${server_url}/api/comments`, _values)
                .then(response => response.data.docs)
                .then(data => {
                    // console.log(data)
                })
                .catch(err => err)

            await resetComments();
            setReplyValues({
                ...initialStateNewComment,
                parent_comment_id: comment.id,
            })
        } catch (e) {
            console.error(e.message);
        }
    }

    const deleteComment = async () => {
        try {
            if (_associatedComments.length > 0) {
                _associatedComments.map(async comment => {
                    await axios.delete(`${server_url}/api/comments/${comment.id}`)
                        // .then(data => console.log(data))
                        .catch(err => err)
                })
            }
            const { id } = comment;

            await axios.delete(`${server_url}/api/comments/${id}`)
                .catch(err => err);

            await resetComments();
        } catch (e) {
            return e;
        }
    }

    return (
        <Fragment>
            <div className="single-comment-wrapper mt-3 d-flex flex-column px-3 py-3"
                style={{
                    borderLeft: "1px solid lightgray",
                    borderBottom: "1px solid lightgray",
                }}
            >
                {/* <div className="blog-comment-img">
                <img
                    src={process.env.PUBLIC_URL + "/assets/img/blog/comment-1.jpg"}
                    alt=""
                />
            </div> */}
                <div className="blog-comment-content">
                    {author && <h4> {isMyComment ? `You` : author} </h4>}

                    <span> {_createdAt && _createdAt} </span>

                    {!editMode && <div
                        className="mb-1"
                        style={{
                            // border: "1px solid red",
                            padding: "0.7rem",
                            minHeight: "4.5rem"
                        }}
                    >
                        {editValues.content}
                    </div>}
                    {editMode && <div className="text-leave">
                        <textarea
                            className={`text-area-blog-comment edit-mode-off`}
                            placeholder="Comment..." defaultValue={editValues.content}
                            readOnly={editMode ? false : true}
                            onChange={(e) => {
                                setEditValues({
                                    ...editValues,
                                    content: e.target.value
                                })
                            }}
                        />
                        <p className="error-text"> {errorsEditValues[0]} </p>
                    </div>}
                </div>
                {replyMode && <div>
                    <textarea
                        className={`text-area-blog-comment`}
                        placeholder="Reply..."
                        defaultValue={replyValues.content}
                        onChange={(e) => {
                            setReplyValues({
                                ...replyValues,
                                content: e.target.value
                            })
                        }}
                    />
                    <p
                        className="error-text"
                    >
                        {errorsReplyValues[0]}
                    </p>
                </div>}
                <div className="d-flex flex-row">
                    {!isMyComment && !replyMode && <i
                        className="comment-button reply-btn-blog-comment fa-solid fa-reply mx-2"
                        onClick={() => {
                            if (editMode) return;
                            setReplyMode(true)
                        }}
                    ></i>}
                    {isMyComment && !editMode && <i
                        className="comment-button edit-btn-blog-comment fa-solid fa-pen-to-square mx-2"
                        onClick={() => {
                            if (replyMode) return;
                            setEditMode(true)
                        }}>
                    </i>}
                    {
                        (editMode === true || replyMode === true) &&
                        <i
                            className="comment-button save-btn-blog-comment fa-solid fa-floppy-disk mx-2"
                            onClick={() => {
                                if (replyMode) {
                                    createNewReplyComment();
                                    setReplyMode(false);
                                    console.log("Reply Mode")
                                } else if (editMode) {
                                    updateComment();
                                    setEditMode(false);
                                    console.log("Update Mode")
                                }
                            }}>
                        </i>
                    }
                    <i
                        className="comment-button fa-solid fa-trash mx-2"
                        onClick={() => {
                            deleteComment()
                        }}
                    ></i>
                </div>
            </div>
            {_associatedComments?.length > 0 && (
                <div
                    className="container-associated-comments mx-3"
                >
                    {_associatedComments.map(comment => (
                        <Comment key={comment.id} comment={comment} resetComments={resetComments} />
                    ))}
                </div>
            )}
        </Fragment>
    )
}

export default BlogCommentDynamic;
