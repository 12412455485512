const phoneUtil = require('libphonenumbers').PhoneNumberUtil.getInstance();

// true
export const isValidPhoneNumber = (number, countryCode) => {
    if (!number || number.length <= 0 || number.length > 16) return false;
    const arr = ['+', '-', '(', ')', ' ', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
    const items = number.split("");
    for (let item of items) {
        if (!arr.includes(item)) {
            return false;
        }
    }
    return true;
}

export function validateString(str) {
    // const regex = /^[a-zA-Z]+$/;
    // return regex.test(str);
    return true;
}