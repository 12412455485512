import { createContext, useContext, useEffect, useState } from "react";
import googleCredentials from './google_credentials/google_client_secret.json';

const AuthContext = createContext();


export const useAuth = () => useContext(AuthContext);


export const AuthProvider = ({ children }) => {
    let value = {};

    return (
        <AuthContext.Provider value={value} >
            { children }
        </AuthContext.Provider>
    )
}