import React, { useEffect, useState } from 'react'
// import { useUser } from '../../context/User/UserContext'
import { useMedusa } from '../../context/medusa/MedusaContext'
import { default_currency } from '../../environmentVariables/env'

export const Eur = ({ width = "", height = "" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : "16px"} height={height ? height : "16px"} style={{ marginTop: "-0.2rem" }} fill="currentColor" class="bi bi-currency-euro" viewBox="0 0 16 16" >
            <path d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0 0 10.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z" />
        </svg>
    )
}

export const Eur_FW = ({ color, width, height, style }) => {
    return (
        // <i className="fa-solid fa-euro-sign" style={{ color, width, height }}></i>
        <span style={{ ...style, color: color, fontSize: style?.fontSize ? style.fontSize : width }}>€</span>
    )
}

export const Cart = ({ width, height }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : "16px"} height={height ? height : "16px"} fill="currentColor" class="bi bi-cart2" viewBox="0 0 16 16">
            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
        </svg>
    )
}


function CurrencyIcon({ style }) {
    const {
        medusa
    } = useMedusa();

    const [currency, setCurrency] = useState("");

    useEffect(() => {
        if (medusa) {
            medusa.regions.list()
                .then(({ regions }) => {
                    const _currencies = regions.map(el => el.currency_code.toUpperCase());
                    const _currency = _currencies.find(c => c === default_currency);
                    setCurrency(_currency);
                })
        }
    }, [medusa])

    if (currency.toLowerCase() === "eur") {
        return <Eur_FW style={style} />
    }
    else if (currency.toLowerCase() === "usd") {
        return <div>$</div>
    }
}

export default CurrencyIcon