import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import React from 'react';
import { PUBLIC_RECAPTCHA_KEY } from "../../environmentVariables/env";

export default function GoogleReCaptchaWrapper({
    children
}) {
    const recaptchaKey = PUBLIC_RECAPTCHA_KEY;

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={recaptchaKey ?? "NOT DEFINED"}
        >
            {children}
        </GoogleReCaptchaProvider>
    )
}