import useScrollTop from "../../hooks/use-scroll-top";
import { useMedusa } from "../../context/medusa/MedusaContext";

const ScrollToTop = () => {
    const { stick, onClickHandler } = useScrollTop();

    const {
        // cart modal 
        open
    } = useMedusa();

    if (open) return <></>;

    if (stick) {
        return (
            <button
                aria-label="Scroll to top"
                type="button"
                className="scroll-top"
                onClick={onClickHandler}
            >
                <i className="fa fa-angle-double-up"></i>
            </button>
        );
    }
    return null;
};

export default ScrollToTop;