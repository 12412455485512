import React from 'react'
import CurrencyIcon from '../../../icons/Icons'

function DeliveryMethod({
    form,
    setForm,
    errors,
    shippingOptions,
}) {

    const setSelectedShippingOption = (shippingOption) => {
        // console.log(shippingOption);
        setForm((prevState) => ({
            ...prevState,
            ["shipping_method"]: shippingOption
        }))
    }

    return (
        <div className="d-flex flex-row justify-items-center align-items-center mb-3">
            <div
                className="radio-button-group d-flex flex-column w-100"
            >
                {shippingOptions.length > 0 && shippingOptions.map((el, idx) => el["metadata"]?.["pickup"] === "true" ? (<></>) : (
                    <>
                        <div
                            key={idx}
                            className="d-flex flex-row justify-content-between align-items-center"
                        >
                            <div className="d-flex flex-row align-items-center">
                                <input
                                    checked={Boolean(form["shipping_method"]?.id === el.id)}
                                    style={{ width: "1.25rem" }}
                                    type="radio" id={el.id}
                                    name="radioGroup"
                                    value={el.id}
                                    onClick={(e) => setSelectedShippingOption(el)}
                                />
                                <label
                                    className="mx-3"
                                    for={el.id}
                                >
                                    {el.name}
                                </label>
                            </div>
                            <div>
                                {
                                    !el.amount ? "Free Delivery" :
                                        <>
                                            <CurrencyIcon /> {Number(el?.amount / 100).toFixed(2)}
                                        </>
                                }

                            </div>
                        </div>
                        {/* <div className='border-bottom'></div> */}
                    </>
                ))}
                <div>
                    <p className="text-danger small">{errors["shipping_method"]?.[0] || ""}</p>
                </div>
            </div>
        </div>
    )
}

export default DeliveryMethod