export function containsSubstring(mainStr, subStr) {
    if (
        !mainStr ||
        !subStr ||
        subStr.length > mainStr.length
    ) return false;
    const _mainStr = mainStr.toLowerCase();
    const _subStr = subStr.toLowerCase();
    const result = _mainStr.includes(_subStr);
    return result;
} 

