import { Link } from 'react-router-dom';
import { useMedusa } from '../../../context/medusa/MedusaContext';
import CurrencyIcon from '../../icons/Icons';
import { Trash } from "@medusajs/icons"
import cogoToast from 'cogo-toast';

export const Card = ({ item: _cart }) => {
    const {
        title: name,
        thumbnail: image,
        id: id,
        // isStocked,
        // productNumber,
        // oldPrice,
        // price,
        // quantity,
    } = _cart;

    const {
        medusa,
        cart,
        setCart
    } = useMedusa();

    const cartItem = _cart;

    const isStocked = (cartItem?.variant?.inventory_quantity > cartItem.quantity) ? true : false
    const productNumber = cartItem?.variant?.sku
    const oldPrice = 0
    const price = Number(cartItem?.unit_price / 100) || 0
    const quantity = cartItem?.quantity || 0

    const handleRemoveFromCart = async () => {
        try {
            await medusa.carts.lineItems.delete(cart.id, cartItem.id)
                .then(({ cart: _c }) => {
                    // console.log(cart.id);
                    setCart(_c);
                })
            cogoToast.success(
                "Product removed from cart",
                { position: "bottom-left" }
            )
        } catch (error) {
            console.error(error);
            cogoToast.error(
                error.message,
                { position: "bottom-left" }
            )
        }
    }

    const handleClickAdd = async () => {
        try {
            await medusa.carts.lineItems.update(cart.id, cartItem.id, {
                quantity: cartItem.quantity + 1
            })
                .then(({ cart: _c }) => {
                    //   console.log(cart.id);
                    setCart(_c);
                })
            cogoToast.success(
                "Quantity updated successfully to " + (quantity + 1) + " for product " + cartItem.title,
                { position: "bottom-left" }
            )
        } catch (error) {
            console.error(error);
            cogoToast.error(
                error.message,
                { position: "bottom-left" }
            )
        }
    }

    const handleClickSubstract = async () => {
        try {
            await medusa.carts.lineItems.update(cart.id, cartItem.id, {
                quantity: cartItem.quantity - 1
            })
                .then(({ cart: _c }) => {
                    //   console.log(cart.id);
                    setCart(_c);
                })
            cogoToast.success(
                "Quantity updated successfully to " + (quantity + 1) + " for product " + cartItem.title,
                { position: "bottom-left" }
            )
        } catch (error) {
            console.error(error);
            cogoToast.error(
                error.message,
                { position: "bottom-left" }
            )
        }
    }

    return (
        <ul className='product-row d-flex flex-row align-items-center w-100'>
            <li className='col-8'>
                <div className='d-flex flex-row'>
                    <img
                        className='img-fluid'
                        src={image}
                        style={{ 
                            objectFit: "contain", 
                            maxHeight: "100px", 
                            maxWidth: "80px",
                        }}
                    />
                    <div className='d-flex flex-column align-items-start justify-content-between/ ms-2 py-2 px-1 w-100'>
                        <p className='theme-text text-left small'>{cartItem.title}</p>
                        <button
                            onClick={handleRemoveFromCart}
                            className='text-muted btn-muted'
                        >
                            <Trash />
                        </button>
                    </div>
                </div>
            </li>
            <li className='col-2'>
                <QuantityButton
                    quantity={cartItem.quantity}
                    onClickAdd={handleClickAdd}
                    onClickSubstract={handleClickSubstract}
                />
            </li>
            <li className='text-end col-2 pr-3'>
                <p className='small'> <CurrencyIcon /> {(cartItem.total / 100).toFixed(2)} </p>
            </li>
        </ul>
    );
};

export const QuantityButton = ({
    quantity,
    onClickAdd,
    onClickSubstract
}) => {
    return (
        <div className='d-flex flex-row align-items-center' style={{ border: "1px solid #cececece", width: "fit-content" }}>
            <button
                className='btn-muted px-2 theme-text d-flex align-items-center justify-content-center cursor-pointer'
                type='button'
                onClick={onClickSubstract}
                style={{ 
                    borderRight: "1px solid #cececece",
                    width: "20px",
                    height: "40px",
                }}
            >
                -
            </button>
            <div 
                className='theme-color d-flex align-items-center justify-content-center text-xs'
                style={{
                    width: "20px",
                    height: "40px", 
                }}
            >
                {quantity}
            </div>
            <button
                className='btn-muted theme-text d-flex align-items-center justify-content-center cursor-pointer'
                type='button'
                style={{ 
                    borderLeft: "1px solid #cececece",
                    width: "20px",
                    height: "40px",
                }}
                onClick={onClickAdd}
            >
                +
            </button>
        </div>
    )
}

// return (
//     <>
//         <div className='cart-table-modal__row d-flex flex-row align-items-center justify-content-between'>
//             <div
//                 className='cart-table__col test-border'
//                 style={{ width: "350px" }}
//             >
//                 <Link href={`/product/${id}`}>
//                     <a className='cart-table__img'>
//                         <img src={image} className='js-img' alt='' />
//                     </a>
//                 </Link>
//                 <div className='cart-table__info'>
//                     <Link href={`/product/${id}`}>
//                         <a className='text-md'>{name}</a>
//                     </Link>
//                     {isStocked && (
//                         <span className='cart-table__info-stock'>in stock</span>
//                     )}
//                     {productNumber && <span className='cart-table__info-num'>SKU: {productNumber}</span>}
//                 </div>
//             </div>
//             <div className='cart-table__col test-border' style={{ width: "100px" }}>
//                 <div className='cart-table__quantity'>
//                     <div className='counter-box'>
//                         <span
//                             onClick={() => onChangeQuantity('decrement', quantity)}
//                             className='counter-link counter-link__prev'
//                         >
//                             <i className='icon-arrow'></i>
//                         </span>
//                         <input
//                             type='text'
//                             className='counter-input'
//                             disabled
//                             value={quantity}
//                         />
//                         <span
//                             onClick={() => onChangeQuantity('increment', quantity)}
//                             className='counter-link counter-link__next'
//                         >
//                             <i className='icon-arrow'></i>
//                         </span>
//                     </div>
//                 </div>
//             </div>
//             <div className='cart-table__col test-border'>
//                 <p className='font-bold'>
//                     <CurrencyIcon /> {(cartItem.total / 100).toFixed(2)}
//                 </p>
//             </div>
//         </div>
//     </>
// );