import { createContext, useState, useEffect, useContext } from "react";
import { useMedusa } from "../medusa/MedusaContext";
import { website_region, default_currency } from "../../environmentVariables/env";
import useLocalStorage from "../../hooks/useLocalStorage"
import { SHA256 } from 'crypto-js';
import { crypto_key } from "../../environmentVariables/env";
import jwtDecode from "jwt-decode";

const UserContext = createContext();


export const useUser = () => useContext(UserContext);


export const UserProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    
    // USER settings
    const [user, setUser] = useLocalStorage("medusaUser", null);
    const [userLoggedOut, setUserLoggedOut] = useState(false);
    
    // REGION & CURRENCIES settings
    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState(null);

    const [currencies, setCurrencies] = useState([]);
    const [currency, setCurrency] = useState("");
    const updateCurrency = curr => setCurrency(curr);

    const { medusa } = useMedusa();

    const updateUserLoggedOut = flag => setUserLoggedOut(flag);

    useEffect(() => {
        if (!medusa) return;

        medusa.regions.list()
            .then(({ regions }) => {
                setRegions(regions);
                const _currencies = regions.map(el => el.currency_code.toUpperCase());
                const _currency = _currencies.find(c => c === default_currency);
                const _region = regions.find(r => r.currency_code.toLowerCase() === _currency.toLowerCase());
                // console.log(_currencies, _currency, _region);
                setRegion(_region);
                setCurrency(_currency);
                setCurrencies(_currencies);
            })

        fetch_N_updateUser();
    }, [medusa]);

    // useEffect(() => {
    //     console.log(user)
    // }, [user])

    useEffect(() => {
        const _region = regions.find(r => r.currency_code.toLowerCase() === currency.toLowerCase());
        // console.log("///////////////////", _region)
        setRegion(_region);
    }, [currency])

    const fetch_N_updateUser = async () => {
        if (user != null) return;
        try {
            await medusa.auth.getSession()
                .then(({ customer }) => {
                    setUser(customer);
                })
                .catch(err => {
                    logoutUser();
                    return err;
                })
        } catch (error) {
            // console.error(error);
            logoutUser();
            setUser(null);
        }
    }

    const handleGoogleUserRegisterLogin = async _response => {
        if (!_response) return;
        try {
            const _user = jwtDecode(_response.credential);
            // console.log(_user);
            let customerExist = false;
            await medusa.auth.exists(_user.email)
                .then((response) => {
                    customerExist = response.exists;
                });
            if (customerExist) {
                const email = _user.email;
                const password = SHA256(_user.email).toString();
                const response = await loginUser({
                    email: email,
                    password: password
                });
                // console.log(response);
                if (response.success) return response;
                else throw new Error(response.error);
            } else {
                const arr = _user.name.split(" ");
                const email = _user.email;
                const first_name = arr[0];
                const last_name = arr.length > 1 ? arr[arr.length - 1] : "";
                const password = SHA256(_user.email).toString();
                const response = await registerNewUser({
                    email: email,
                    password: password,
                    first_name: first_name,
                    last_name: last_name
                });
                // console.log(response);
                if (response.success) return response;
                else throw new Error(response.error);
            }
        } catch (error) {
            // console.error(error);
            return { success: false, error: error.message };
        }
    }

    const registerNewUser = async _newUser => {
        if (!_newUser) return;
        try {
            // check if the user exists;
            let customerExist = false;
            await medusa.auth.exists(_newUser.email)
                .then((response) => {
                    customerExist = response.exists
                });
            if (customerExist) throw new Error("User already exists, try logging in");

            setLoading(true);
            let userRegistered = false;
            await medusa.customers.create({
                email: _newUser.email,
                password: _newUser.password,
                first_name: _newUser.firstName,
                last_name: _newUser.lastName,
                // metadata: { cart_id: "" }
            })
                .then(async ({ customer }) => {
                    const res = await loginUser({ email: _newUser.email, password: _newUser.password });
                    if (res?.success === false) throw new Error(res?.error);
                    else userRegistered = true;
                });
            if (!userRegistered) throw new Error("Unable to create user account");
            else return { success: true, message: "User account created successfully" };
        } catch (error) {
            // console.error(error);
            setLoading(false);
            return { success: false, error: error };
        }
    }

    const loginUser = async _user => {
        if (!_user) return;
        try {
            let customerExist = false;
            if (loading === true) {
                // this means the user just signed up, so the customer is triying to login exists.
                customerExist = true;
            } else {
                await medusa.auth.exists(_user.email)
                    .then((response) => {
                        customerExist = response.exists
                    });
                setLoading(true)
            }
            if (!customerExist) throw new Error("User does not exists, try register");

            let userLoggedIn = false;
            await medusa.auth.authenticate({
                email: _user.email,
                password: _user.password,
            })
                .then(({ customer }) => {
                    setUser(customer);
                    userLoggedIn = true;
                    setLoading(false);
                });
            if (!userLoggedIn) throw new Error("User not logged in");
            else return { success: true, message: "User successfully logged in" };
        } catch (error) {
            // console.error(error);
            setLoading(false);
            return { success: false, error: error };
        }
    }

    const updateUser = async _updatedUser => {
        if (!_updatedUser) return;
        try {
            // console.log(_updatedUser);
            setLoading(true);
            return await medusa.customers.update(_updatedUser)
                .then(({ customer }) => {
                    // console.log(customer);
                    setUser(customer);
                    return customer;
                })
                .catch(err => {
                    return err;
                })
                .finally(() => {
                    setLoading(false);
                })
        } catch (error) {
            return { success: false, error: error };
        }
    }

    const logoutUser = async () => {
        if (!user) return;
        try {
            await medusa.auth.deleteSession()
                .then(() => {
                    // success
                    setUser(null);
                    updateUserLoggedOut(true);
                })
            if (userLoggedOut) return { success: true, message: "User logged out" };
            else throw new Error("Unable to log out user");
        } catch (error) {
            // console.error(error);
            return { success: false, error: error };
        }
    }

    const createNewAddress = async _newAddress => {
        if (!_newAddress) return;
        try {
            setLoading(true);
            let addressCreated = false;
            let adrs = null;
            return await medusa.customers.addresses.addAddress({
                address: { ..._newAddress }
            })
                .then(({ customer }) => {
                    addressCreated = true;
                    setUser({ ...customer });
                    setLoading(false);
                    adrs = customer?.shipping_addresses?.find(a =>
                        a.first_name === _newAddress.first_name &&
                        a.last_name === _newAddress.last_name &&
                        a.address_1 === _newAddress.address_1 &&
                        a.phone === _newAddress.phone &&
                        a.postal_code === _newAddress.postal_code &&
                        a.country_code === _newAddress.country_code &&
                        a.city === _newAddress.city &&
                        a.province === _newAddress.province
                    )
                    return { success: true, message: "New Shipping Address added", address: adrs };
                })
                .catch(err => err);
        } catch (error) {
            // console.error(error);
            setLoading(false);
            return { success: false, error: error };
        }
    }

    const updateAddress = async (_id, _updatedAddress) => {
        if (!_id || !_updatedAddress) return;
        console.log(_id, _updatedAddress);
        try {
            let addressUpdated = false;
            setLoading(true);
            await medusa.customers.addresses.updateAddress(_id, _updatedAddress)
                .then(({ customer }) => {
                    addressUpdated = true;
                    setUser({ ...customer });
                    setLoading(false);
                });
            if (!addressUpdated) throw new Error("Unable to update address");
            else return { success: true, message: "Shipping Address updated" };
        } catch (error) {
            // console.error(error);
            setLoading(false);
            return { success: false, error: error };
        }
    }

    const deleteAddress = async _id => {
        if (!_id) return;
        try {
            let isDeleted = false;
            setLoading(true)
            await medusa.customers.addresses.deleteAddress(_id)
                .then(({ customer }) => {
                    setUser({ ...customer });
                    setLoading(false);
                    isDeleted = true
                });
            if (!isDeleted) throw new Error("Unable to delete address");
            else return { success: true, message: "Shipping Address delete" };
        } catch (error) {
            // console.error(error);
            setLoading(false);
            return { success: false, error: error };
        }
    }

    // useEffect(() => {
    //     console.log("//////", user)
    // }, [user])

    let value = {
        // user,
        // setUser: (_user) => setUser(_user),
        // registerNewUser,
        // loginUser,
        // loading,
        // updateUser,
        // logoutUser,
        // userLoggedOut,
        // updateUserLoggedOut,
        // createNewAddress,
        // updateAddress,
        // deleteAddress,
        // regions,
        // region,
        // currencies,
        // currency,
        // updateCurrency,
        // handleGoogleUserRegisterLogin,
    }

    return (
        <UserContext.Provider value={value} >
            {children}
        </UserContext.Provider>
    )
}