import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCMS } from "../../../context/CMS/cmsContext";
// import { useUser } from "../../../context/User/UserContext";
import { useMedusa } from "../../../context/medusa/MedusaContext";

const MobileNavMenu = () => {
  const { t } = useTranslation();

  const { user } = useMedusa();
  const { blogs } = useCMS();

  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        {!user && <li className="d-flex flex-row">
          <Link to={process.env.PUBLIC_URL + "/login-register/login"}>
            <button className="btn-primary">
              Login
            </button> 
          </Link>
          <Link to={process.env.PUBLIC_URL + "/login-register/register"}>
            <button className="btn-primary mx-2">
              Signup
            </button>
          </Link>
        </li>}
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/"}>{t("home")}</Link>
          <ul className="sub-menu">
            <li className="menu-item-has-children">
              <Link to={process.env.PUBLIC_URL + "/"}>
                {t("Hiring Online")}
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-fashion"}>
                    {t("The Hire Process")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-fashion-two"}>
                    {t("About Ardo Breastpumps")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-fashion-three"}>
                    {t("Why Hospital Grade")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-fashion-four"}>
                    {t("Hire Online or Locally")}
                  </Link>
                </li>
                {/* <li>
                  <Link to={process.env.PUBLIC_URL + "/home-fashion-five"}>
                    {t("home_fashion_five")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-fashion-six"}>
                    {t("home_fashion_six")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-fashion-seven"}>
                    {t("home_fashion_seven")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-fashion-eight"}>
                    {t("home_fashion_eight")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-kids-fashion"}>
                    {t("home_kids_fashion")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-cosmetics"}>
                    {t("home_cosmetics")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-furniture"}>
                    {t("home_furniture")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-furniture-two"}>
                    {t("home_furniture_two")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-furniture-three"}>
                    {t("home_furniture_three")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-furniture-four"}>
                    {t("home_furniture_four")}
                  </Link> 
  </li> */}
              </ul>
            </li>
            <li className="menu-item-has-children">
              <Link to={process.env.PUBLIC_URL + "/"}>
                {t("Buying Online")}
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-furniture-five"}>
                    {t("Returns Policy")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-furniture-six"}>
                    {t("Delivery Information")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-furniture-seven"}>
                    {t("Customer Care")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-electronics"}>
                    {t("Return Depots")}
                  </Link>
                </li>
                {/* <li>
                  <Link to={process.env.PUBLIC_URL + "/home-electronics-two"}>
                    {t("home_electronics_two")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-electronics-three"}>
                    {t("home_electronics_three")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-book-store"}>
                    {t("home_book_store")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-book-store-two"}>
                    {t("home_book_store_two")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-plants"}>
                    {t("home_plants")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-flower-shop"}>
                    {t("home_flower_shop")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-flower-shop-two"}>
                    {t("home_flower_shop_two")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-organic-food"}>
                    {t("home_organic_food")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-organic-food-two"}>
                    {t("home_organic_food_two")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-onepage-scroll"}>
                    {t("home_onepage_scroll")}
                  </Link>
                </li> */}
              </ul>
            </li>
            {/* <li className="menu-item-has-children">
              <Link to={process.env.PUBLIC_URL + "/"}>
                {t("home_group_three")}
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-grid-banner"}>
                    {t("home_grid_banner")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-auto-parts"}>
                    {t("home_auto_parts")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-cake-shop"}>
                    {t("home_cake_shop")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-handmade"}>
                    {t("home_handmade")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-pet-food"}>
                    {t("home_pet_food")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-medical-equipment"}>
                    {t("home_medical_equipment")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-christmas"}>
                    {t("home_christmas")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-black-friday"}>
                    {t("home_black_friday")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-black-friday-two"}>
                    {t("home_black_friday_two")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/home-valentines-day"}>
                    {t("home_valentines_day")}
                  </Link>
                </li>
              </ul>
            </li> */}
          </ul>
        </li>

        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
            {t("CATEGORIES")}
          </Link>
          <ul className="sub-menu">
            <li className="menu-item-has-children">
              <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                {t("BUYING")}
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                    {t("Breast Pumps")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/shop-grid-filter"}>
                    {t("Pump Accessories")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/shop-grid-two-column"}>
                    {t("Hygiene Kits")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/shop-grid-no-sidebar"}>
                    {t("Skincare")}
                  </Link>
                </li>
                {/* <li>
                  <Link to={process.env.PUBLIC_URL + "/shop-grid-full-width"}>
                    {t("shop_grid_full_width")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={process.env.PUBLIC_URL + "/shop-grid-right-sidebar"}
                  >
                    {t("shop_grid_right_sidebar")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/shop-list-standard"}>
                    {t("shop_list_standard")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/shop-list-full-width"}>
                    {t("shop_list_full_width")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/shop-list-two-column"}>
                    {t("shop_list_two_column")}
                  </Link>
                </li> */}
              </ul>
            </li>
            <li className="menu-item-has-children">
              <Link to={process.env.PUBLIC_URL + "/product/1"}>
                {t("HIRING")}
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/product/1"}>
                    {t("Double Pumps")}
                  </Link>
                </li>
                {/* <li>
                  <Link to={process.env.PUBLIC_URL + "/product-tab-left/1"}>
                    {t("")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/product-tab-right/1"}>
                    {t("product_tab_right")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/product-sticky/1"}>
                    {t("product_sticky")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/product-slider/1"}>
                    {t("product_slider")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/product-fixed-image/1"}>
                    {t("product_fixed_image")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/product/1"}>
                    {t("product_simple")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/product/1"}>
                    {t("product_variation")}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/product/1"}>
                    {t("product_affiliate")}
                  </Link>
                </li> */}
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
            {t("SHOP NOW")}
          </Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/store-locations"}>
            {t("STORE LOCATIONS")}
          </Link>
        </li>
        {/* <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/"}>{t("pages")}</Link>
          <ul className="sub-menu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/cart"}>
                {t("cart")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/checkout"}>
                {t("checkout")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/wishlist"}>
                {t("wishlist")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/compare"}>
                {t("compare")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/my-account"}>
                {t("my_account")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/login-register"}>
                {t("login_register")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/about"}>
                {t("about_us")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/contact"}>
                {t("contact_us")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/not-found"}>
                {t("404_page")}
              </Link>
            </li>
          </ul>
        </li> */}
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/blog-standard"}>
            {t("Advice")}
          </Link>
          <ul className="sub-menu">
            {blogs.length > 0 && blogs.map((blog, idx) => (
              <li key={idx}>
                <Link to={process.env.PUBLIC_URL + `/${blog?.slug_url}`}>
                  {t(blog?.blog_title)}
                </Link>
              </li>
            ))}
            {/* <li>
              <Link to={process.env.PUBLIC_URL + "/blog-ireland"}>
                {t("breastfeeding ireland")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-ardo"}>
                {t("about ardo")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-rights"}>
                {t("know your rights")}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-sleep"}>
                {t("sleeping challenges")}
              </Link>
            </li> */}
          </ul>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/contact"}>
            {t("contact_us")}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
