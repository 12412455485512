import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
// import BlogSidebar from "../../wrappers/blog/BlogSidebar";
// import BlogComment from "../../wrappers/blog/BlogComment";
// import BlogPost from "../../wrappers/blog/BlogPost2";
import BlogSidebarDynamic from "../../wrappers/blog/BlogSidebarDynamic";
import BlogCommentDynamic from "../../wrappers/blog/BlogCommentDynamic";
import BlogPostDynamic from "../../wrappers/blog/BlogPostDynamic";

const BlogBenefits = ({ blogs, blog }) => {
  let { pathname } = useLocation();

  return (
    <Fragment>
      <SEO
        titleTemplate={blog?.template_title}
        description={blog?.template_description}
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Blog", path: process.env.PUBLIC_URL + "/" },
            { label: blog?.blog_title, path: process.env.PUBLIC_URL + pathname }
          ]}
        />
        <div className="blog-area pt-50 pb-100">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-lg-9">
                <div className="blog-details-wrapper ml-20">
                  {/* blog post */}
                  {blog && <BlogPostDynamic blog={blog} />}

                  {/* blog post comment */}
                  {blog?.enable_comments && <BlogCommentDynamic blog={blog} />}
                </div>
              </div>
              <div className="col-lg-3">
                {/* blog sidebar */}
                {blog && <BlogSidebarDynamic />}
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default BlogBenefits;
