import React from 'react'

function Title({
    number,
    title,
    flag = false,
    onClick = null,
}) {
    return (
        <div
            className="container-title-checkout px-4 py-4 d-flex flex-row align-items-center justify-content-between mb-3"
            style={{
                // background: "var(--bg-gray-btn-hover)",
                maxHeight: "5rem"
            }}
            onClick={() => onClick && onClick()}
        >
            <div
                className="d-flex justify-content-center align-items-center cursor-pointer"
                style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "1000px",
                    background: "black",
                    color: "white",
                    fontSize: "1rem",
                    marginRight: "1rem",
                }}
            > {number} </div>
            <div
                style={{
                    // border: "1px solid red",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // width: "1%"
                    flex: "1"
                }}
            >
                <h3
                    className="cursor-pointer"
                    style={{ marginBottom: "-2px" }}
                >
                    {title}
                </h3>
                {flag && <div
                    className=' d-flex flex-row'
                >
                    <i
                        className="fs-1 fa-solid fa-check"
                        style={{ color: "var(--theme-color)" }}
                    ></i>
                </div>}
            </div>
            {/* <h3 className="">{title}</h3> */}

        </div>
    )
}

export default Title