export const getQueryParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};

    for (const [key, value] of searchParams) {
        params[key] = value;
    }

    return Object.keys(params).length > 0 ? params : null;
};

export function objectToQueryString(obj) {
    if (!obj) return "";
    const queryString = Object.keys(obj)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
        .join('&');
    return queryString;
}