import React, { useState } from 'react';
import "./Switch.css";

const Switch = ({
    value = false,
    onChange = null
}) => {
    const [isChecked, setIsChecked] = useState(value);

    const handleToggle = () => {
        if (onChange) {
            onChange(!isChecked);
        }
        setIsChecked(!isChecked);
    };

    return (
        <label className="switch">
            <input type="checkbox" checked={isChecked} onChange={handleToggle} />
            <span className="slider round"></span>
        </label>
    );
};

export default Switch;
