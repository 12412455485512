import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
// import { setProducts } from "./store/slices/product-slice"
// import products from "./data/products.json";
import 'animate.css';
import 'swiper/swiper-bundle.min.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
// import "./i18n";

// custom
import { MedusaProvider } from "./context/medusa/MedusaContext";
import { UserProvider } from "./context/User/UserContext";
import { ProductProvider } from "./context/product/ProductContext";
import { AuthProvider } from "./context/Authentication/AuthContext";
import './index.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleCredentials from './context/Authentication/google_credentials/google_client_secret.json';
// store.dispatch(setProducts(products));
import { ModalProductProvider } from "./context/product/ProductModalContext";
import { CMSProvider } from "./context/CMS/cmsContext";


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <MedusaProvider>
    <CMSProvider>
      <UserProvider>
        <AuthProvider>
          <GoogleOAuthProvider clientId={GoogleCredentials.web.client_id}>
            <Provider store={store}>
              <ProductProvider>
                <ModalProductProvider>
                  <PersistProvider>
                    <App />
                  </PersistProvider>
                </ModalProductProvider>
              </ProductProvider>
            </Provider>
          </GoogleOAuthProvider>
        </AuthProvider>
      </UserProvider>
    </CMSProvider>
  </MedusaProvider>
);

