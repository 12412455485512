import React, { useEffect } from 'react'
import { QuantityButton } from '../../../cart-modal/Card/Card'
import Container from '../../../checkout-page-components/Container/Container';
import "./card.css";
import cogoToast from 'cogo-toast';
import { useMedusa } from '../../../../context/medusa/MedusaContext';
import CurrencyIcon from '../../../icons/Icons';
import { useNavigate } from 'react-router-dom';

function Card({
    item
}) {
    const navigate = useNavigate();

    const {
        medusa,
        cart,
        setCart
    } = useMedusa();

    if (!item) return <></>;

    let loading = false;

    const handleRemove = async () => {
        try {
            loading = true;
            await updateLineItem({ quantity: 0 });
            cogoToast.success(
                "Product " + item.title + " removed from cart",
                { position: "bottom-left" }
            )
        } catch (error) {
            console.error(error);
            cogoToast.error(
                error.message,
                {
                    position: "bottom-left"
                }
            );
        } finally {
            loading = false;
        }
    }

    const updateLineItem = async (payload) =>
        await medusa.carts.lineItems.update(cart.id, item.id, payload)
            .then(({ cart: _c }) => {
                //   console.log(cart.id);
                setCart(_c);
            });

    const handleClickAdd = async () => {
        const newQuantity = item.quantity + 1;
        if (newQuantity <= item?.variant?.inventory_quantity) {
            await updateLineItem({ quantity: newQuantity });
            cogoToast.success(
                "Quantity updated successfully to " + (newQuantity) + " for product " + item.title,
                { position: "bottom-left" }
            )
        }
    }

    const handleClickSubtract = async () => {
        const newQuantity = item.quantity - 1;
        await updateLineItem({ quantity: newQuantity });
        cogoToast.success(
            "Quantity updated successfully to " + newQuantity + " for product " + item.title,
            { position: "bottom-left" }
        )
    }

    const openUrlInNewTab = (url) => {
        window.open(url, '_blank').focus();
    }

    return (
        <Container style={{ marginBottom: "-1px" }}>
            <div className='col-lg-12 d-flex flex-row'>
                <div className='col-lg-4 '>
                    <div className='item-image-container'>
                        <div className='tags'>
                            {item.variant?.product?.metadata?.hireable && (
                                <div
                                    className="product-badge-custom hire d-flex align-items-center justify-content-center h-full"
                                >
                                    <span style={{ fontSize: "0.65rem", marginBottom: "-2px" }}> Hire </span>
                                </div>
                            )}
                        </div>
                        <img
                            className='thumbnail'
                            src={item.thumbnail}
                        />
                    </div>
                </div>
                <div className='col-lg-8 d-flex flex-column justify-content-between'>
                    <div className='col-lg-12 d-flex flex-row mb-3'>
                        <div className='col-lg-10  '>
                            <p className='fw-bold'>{item.title}</p>
                        </div>
                        <div className='col-lg-2  '>
                            <div
                                className='d-flex flex-column align-items-center justify-content-center'
                            >
                                <button
                                    className="bg-none border"
                                    type='button'
                                    onClick={handleRemove}
                                    disabled={loading}
                                >
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-12 d-flex flex-row'>
                        <div className='col-lg-6   d-flex flex-column align-items-start justify-content-end'>
                            <p className='fw-bold'> Quantity </p>
                            <QuantityButton
                                quantity={item.quantity}
                                onClickAdd={() => handleClickAdd()}
                                onClickSubstract={() => handleClickSubtract()}
                            />
                        </div>
                        <div className='col-lg-6   d-flex flex-column align-items-end justify-content-end'>
                            <p className='fw-bold mx-3'> Price: <CurrencyIcon />{(item.total /100).toFixed(2)} </p>
                            <p
                                className='text-info line-height-1 mx-3'
                                style={{
                                    textDecoration: "underline",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    openUrlInNewTab(window.location.origin + `/product/${item?.variant?.product_id}`)  
                                }}
                            >View Product</p>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Card