import { createContext, useContext, useState, useEffect } from "react";
import { useMedusa } from "../medusa/MedusaContext";
// import { useUser } from "../User/UserContext";

const StockLocationContext = createContext();


export const useStockLocation = () => useContext(StockLocationContext);

const initialStateLocations = [
    {
        id: "xyz",
        name: "Location name 1",
        location_address: {
            id: "abc",
            address_1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
            address_2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
            company: "Lorem",
            city: "ipsum",
            country_code: "eu",
            phone: "8888888888",
            province: "Lorem",
            postal_code: "LO",
            metadata: {
                coordinates_lat: 53.351456,
                coordinates_lon: -6.270103
            }
        }
    },
    {
        id: "abc",
        name: "Location name 2",
        location_address: {
            id: "abc",
            address_1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
            address_2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
            company: "Lorem",
            city: "ipsum",
            country_code: "eu",
            phone: "999999999",
            province: "Lorem",
            postal_code: "LO",
            metadata: {
                coordinates_lat: 50.351456,
                coordinates_lon: -6.270103
            }
        }
    },
    {
        id: "def",
        name: "Location name 3",
        location_address: {
            id: "abc",
            address_1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
            address_2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
            company: "Lorem",
            city: "ipsum",
            country_code: "eu",
            phone: "111111111111",
            province: "Lorem",
            postal_code: "LO",
            metadata: {
                coordinates_lat: 40.351456,
                coordinates_lon: -6.270103
            }
        }
    }
]


export const StockLocationProvider = ({ children }) => {
    const [location, setLocation] = useState([])
    const [selectedLocation, setSelectedLocation] = useState(null);

    const { medusa, region } = useMedusa();

    useEffect(() => {
        if (!medusa || !region) return;

        // call all locations and serLocation
        setLocation(initialStateLocations);

        return () => {
            setLocation([]);
        }
    }, [medusa, region])

    useEffect(() => {
        if (!location) return;
        setSelectedLocation(location[0])
    }, [location]);

    let value = {
        location,
        selectedLocation,
        updateSelectedLocation: (loc) => setSelectedLocation(loc),
    }

    return (
        <StockLocationContext.Provider value={value} >
            {children}
        </StockLocationContext.Provider>
    )
}
