import PropTypes from "prop-types";
import { Helmet, HelmetProvider } from "react-helmet-async";

const SEO = ({
    title,
    description,
    keywords = [],
    robots = [],
    og_title,
    og_description,
    og_type,
    og_url,
    og_image,
    twitter_title,
    twitter_description,
    twitter_card,
    twitter_image,
    twitter_site
}) => {

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8" />
                {title ? (
                    <title> BreastisBest.ie | {title}</title>
                ) : (
                    <title> BreastisBest.ie</title>
                )}
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords.join(",")} />
                <meta name="robots" content={robots.join(",")} />
                <meta property="og:title" content={og_title} />
                <meta property="og:description" content={og_description} />
                <meta property="og:type" content={og_type} />
                <meta property="og:url" content={og_url} />
                <meta property="og:image" content={og_image} />
                <meta property="og:site_name" content="BreastisBest.ie" />
                <meta name="twitter:card" content={twitter_card} />
                <meta name="twitter:creator" content={twitter_site} />
                <meta name="twitter:title" content={twitter_title} />
                <meta name="twitter:description" content={twitter_description} />
                <meta name="twitter:image" content={twitter_image} />
            </Helmet>
        </HelmetProvider>
    );
};

SEO.propTypes = {
    title: PropTypes.string,
    titleTemplate: PropTypes.string,
    description: PropTypes.string,
}

SEO.defaultProps = {
    title: "BreastisBest.ie",
    titleTemplate: "Product Page",
    description: "Product page of BreastisBest.ie - Maternity products for sale or hire.",
};

export default SEO;