import { createContext, useContext, useState, useEffect, useMemo } from "react";
import { isEqual } from "lodash";
import { isAvailableToBuy } from "../../helpers/isAvailableToBuy";
import cogoToast from "cogo-toast";

const ModalProductContext = createContext();


export const useModalProduct = () => useContext(ModalProductContext);


export const ModalProductProvider = ({ children }) => {
    const [product, setProduct] = useState(null);
    const [options, setOptions] = useState({});
    const [variants, setVariants] = useState([]);
    const [inStock, setInStock] = useState(true);
    const [quantity, setQuantity] = useState(1);
    const [maxQuantityMet, setMaxQuantityMet] = useState(false);

    useEffect(() => {
        if (!product) return;
        // initialize the option state
        const optionObj = {}
        for (const option of product.options) {
            Object.assign(optionObj, { [option.id]: undefined })
        }
        setOptions(optionObj)

        // initialize the option state
        setVariants(product?.variants);

        // TO DO
        // If the selected variant is in the medusaCart set the quantity to the medusaCart's quantity | else set to 1

    }, [product])

    // memoized record of the product's variants
    const variantRecord = useMemo(() => {
        if (!variants) return;
        const map = {};
        for (const variant of variants) {
            const tmp = {}
            for (const option of variant.options) {
                tmp[option.option_id] = option.value
            }
            map[variant.id] = tmp
        }
        return map;
    }, [variants]);

    // memoized function to check if the current options are a valid variant
    const variant = useMemo(() => {
        let variantId = undefined;
        for (const key of Object.keys(variantRecord)) {
            if (isEqual(variantRecord[key], options)) {
                variantId = key;
            }
        }
        return variants.find((v) => v.id === variantId);
    }, [options, variantRecord, variants]);

    // Important 
    useEffect(() => {
        if (variants.length === 1) {
            setOptions(variantRecord[variants[0].id])
        } else if (variants.length >= 1) {
            setOptions(variantRecord[variants[0].id])
        }
    }, [variants, variantRecord])

    const disabled = useMemo(() => {
        return !variant
    }, [variant])

    const updateProduct = prod => {
        if (!prod) return;
        return setProduct(prod);
    }

    const updateOptions = newOp => {
        if (!newOp) return;
        setOptions({ ...options, ...newOp })
    }

    useEffect(() => {
        if (!variant) return;
        setInStock(() => {
            return isAvailableToBuy(variant);
        });
    }, [variant]);

    const increaseQuantity = () => {
        if (!variant) return cogoToast.warn("Select a variant", { position: "bottom-left" });
        const maxQuantity = variant?.inventory_quantity || 0
        // console.log(maxQuantity);
        if (maxQuantity > quantity + 1) {
            setQuantity(quantity + 1)
        } else {
            setMaxQuantityMet(true);
            // dispatch a notification that max quantity reached.
            cogoToast.warn("Maximum quantity reached", { position: "bottom-left" });
        }
    }

    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
            if (maxQuantityMet) {
                setMaxQuantityMet(false)
            }
        }
    }

    // TESTING
    // useEffect(() => {
    //     console.log("Variants/////////", variants)
    // }, [variants])

    // useEffect(() => {
    //     console.log("Product/////////", product)
    // }, [product])

    // useEffect(() => {
    //     console.log("Variant/////////", variant)
    // }, [variant])

    let value = {
        product,
        updateProduct,
        options,
        updateOptions,
        disabled,
        variant,
        variants,
        inStock,
        quantity,
        increaseQuantity,
        decreaseQuantity,
    }

    return (
        <ModalProductContext.Provider value={value}>
            {children}
        </ModalProductContext.Provider>
    )
}