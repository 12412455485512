import React from 'react'
import "./checkbox.css";

function Checkbox({
    onChange,
    value,
    className
}) {
    if (value === true) {
        return (
            <div className={`checkbox checked ${className}`} onClick={() => onChange(false)}>
                <i className="icon fa-solid fa-check"></i>
            </div>
        )
    } else {
        return (
            <div className={`checkbox un-checked ${className}`} onClick={() => onChange(true)}>

            </div>
        )
    }
}

export default Checkbox