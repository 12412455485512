export const beautifyComments = (comments) => {
    if (!comments.length) return [];

    const commentMap = new Map();
    for (const comment of comments) {
        comment.associatedComments = [];
        commentMap.set(comment.id, comment);
    }
    
    // Populate associatedComments array for each comment
    for (const comment of comments) {
        const parentCommentId = comment?.parent_comment_id;
        if (parentCommentId) {
            const parentComment = commentMap.get(parentCommentId);
            if (parentComment) {
                parentComment.associatedComments.push(comment);
            }
        }
    }

    // Find top-level comments (comments without parent_comment_id)
    const topLevelComments = comments.filter(comment => !comment?.parent_comment_id || comment.parent_comment_id === "");

    return topLevelComments;
}
