import React from 'react'

function Container({ 
    children,
    className,
    style
}) {
  return (
    <div className={`border border-gray px-4 py-4 ${className}`} style={style ? style : {}}>
        {children}
    </div>
  )
}

export default Container