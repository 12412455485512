export const website_region = "Ireland";
export const default_currency = "EUR";
export const website_name = "BreastisBest";
export const website_prefix = "bib_";
export const crypto_key = "bib_";
// export const server_url = "http://localhost:9001";
// export const server_url ="http://srv452348.hstgr.cloud:9001"
export const server_url = "https://app.breastisbest.ie"
export const shop_page_products = 15
export const GOOGLE_MAP_API_KEY = "AIzaSyDj1y87n5Nw3wctl5PFgQtmINNPGuGwHAQ"
export const WEBSITE_REGION_NAME = "Ireland";
export const PUBLIC_RECAPTCHA_KEY = "6LegyOspAAAAAOlXReLYU91_P2e4oPqgBJjLOate";
export const test_users = ["niall.bodkin@gmail.com", "harkiratsinghsur@gmail.com"];
