import React, { Fragment, useState } from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import SEO from '../../components/seo'
import LayoutOne from '../../layouts/LayoutOne'
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { server_url } from '../../environmentVariables/env';
import draftToHtml from "draftjs-to-html";

function Page({ page }) {
    const { pathname } = useLocation();
    const [html, setHtml] = useState([]);

    useEffect(() => {
        if (!page) return;
        // const contentHtmlString = serializeToHtml(page?.content)
        // setHtml(() => htmlStringToHtmlObject(contentHtmlString));
        setHtml(() => draftToHtml(page.content));
        // console.log(pathname, page);
    }, [page])

    // useEffect(() => {
    //     console.log(html)
    // }, [html])

    return (
        <Fragment>
            <SEO
                titleTemplate={page?.template_title}
                description={page?.template_description}
            />
            <LayoutOne headerTop={"visible"}>
                <Breadcrumb
                    pages={[
                        { label: "Page", path: process.env.PUBLIC_URL + "/" },
                        { label: page?.template_title, path: process.env.PUBLIC_URL + pathname }
                    ]}
                />
                <div className="blog-area pt-50 pb-100">
                    <div className="container">
                        <div className="row flex-row-reverse">
                            <div className="col-lg-12">
                                <div className="blog-details-wrapper">
                                    <div className="blog-details-img mb-5">
                                        <img
                                            alt=""
                                            src={server_url + `${page?.featured_image?.url}`}
                                            style={{ width: "100%", objectFit: "contain" }}
                                        />
                                    </div>

                                    <div dangerouslySetInnerHTML={{ __html: html }} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutOne>
        </Fragment>
    )
}

export default Page