import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { server_url } from "../../environmentVariables/env";
// import { serializeToHtml, htmlStringToHtmlObject } from "../../helpers/htmlConverter";
import { beautifyDate } from "../../helpers/beautifyDate";
import draftToHtml from "draftjs-to-html";

const BlogPostDynamic = ({ blog }) => {
    const [html, setHtml] = useState([])

    const _createdAt = beautifyDate(blog.createdAt)

    useEffect(() => {
        if (!blog) return;
        setHtml(() => draftToHtml(blog.content));
    }, [blog])


    return (
        <Fragment>
            <div className="blog-details-top">
                <div className="blog-details-img">
                    <img
                        alt=""
                        src={server_url + `${blog?.featured_image?.url}`}
                    />
                </div>
                <div className="blog-details-content">
                    <div className="blog-meta-2">
                        <ul>
                            <li> {_createdAt} </li>
                            <li>
                                <Link to={process.env.PUBLIC_URL + "/blog-details-standard"}>
                                    4 <i className="fa fa-comments-o" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                </div>
            </div>
            <div className="dec-img-wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <div className="dec-img mb-50">
                            <img
                                alt=""
                                src={
                                    server_url + `${blog.sub_image_A?.url}`
                                }
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="dec-img mb-50">
                            <img
                                alt=""
                                src={
                                    server_url + `${blog?.sub_image_B?.url}`
                                }
                            />
                        </div>
                    </div>
                </div>
                <p>
                    BreastisBest.ie is exclusively hires and sells Ardo products. You can hire online on this website or though one of our hire outlets throughout Ireland. Ardo swiss precision pumps are specially designed for maximum comfort for mother and baby. Find out more on the hiring process.
                </p>
            </div>
            <div className="tag-share">
                {/* <div className="dec-tag">
          <ul>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-standard"}>
                lifestyle ,
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-standard"}>
                interior ,
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/blog-standard"}>
                outdoor
              </Link>
            </li>
          </ul>
        </div> */}
                <div className="blog-share">
                    <span>share :</span>
                    <div className="share-social">
                        <ul>
                            <li>
                                <a className="facebook" href="//facebook.com">
                                    <i className="fa fa-facebook" />
                                </a>
                            </li>
                            <li>
                                <a className="twitter" href="//twitter.com">
                                    <i className="fa fa-twitter" />
                                </a>
                            </li>
                            <li>
                                <a className="instagram" href="//instagram.com">
                                    <i className="fa fa-instagram" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="next-previous-post">
                <Link to={process.env.PUBLIC_URL + "/blog-latching"}>
                    {" "}
                    <i className="fa fa-angle-left" /> prev post
                </Link>
                <Link to={process.env.PUBLIC_URL + "/blog-rights"}>
                    next post <i className="fa fa-angle-right" />
                </Link>
            </div>
        </Fragment>
    );
};

export default BlogPostDynamic;
