import React from 'react'
import Spinner from '../../../spinner/Spinner'

function CreatingOrder() {
  return (
    <div className='container'>
        <div
            className='d-flex flex-column align-items-center justify-content-center' 
            style={{
                height: "60vh"
            }}
        >
            <div
                className='mb-4'
                style={{
                    scale: "2"
                }}
            >
                <Spinner color='secondary' />
            </div>
            <p className='text-center'> Payment Successful <br /> Creating Order in Progress </p>
        </div>
    </div>
  )
}

export default CreatingOrder