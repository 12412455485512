export function beautifyDate(isoDateString) {
    if (!isoDateString) return "";
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const isoDate = new Date(isoDateString);
    const beautifiedDate = isoDate.toLocaleDateString('en-US', options);
    return beautifiedDate;
}

export function beautifyDateTime(isoDateString) {
    if (!isoDateString) return "";
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    };

    const date = new Date(isoDateString);
    return date.toLocaleDateString("en-US", options);
}

export function formatToDDMMYYYY(isoDateString) {
    if (!isoDateString) return "";

    const date = new Date(isoDateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}
