import React, {
    Fragment,
    useEffect,
    useState,
    useMemo
} from 'react'
import { useMedusa } from '../../context/medusa/MedusaContext'
import Title from '../checkout-page-components/Title';
import Container from '../checkout-page-components/Container/Container';
import Switch from '../switch/Switch';
import { getQueryParams } from '../../helpers/getQueryParams';
import { containsErrors } from '../../helpers/containsErrors';
import { countries } from '../../data/Countries';
import axios from 'axios';
import { server_url } from '../../environmentVariables/env';
import cogoToast from 'cogo-toast';
import { useNavigate } from 'react-router-dom';

// Forms
import UserDetails from './components/form/user-details';
import AddressForm from './components/form/address';
import CollectLocation from '../checkout-page-components/CollectLocation/CollectLocation';
import DeliveryMethod from './components/form/delivery-method';
import OrderDetails from './components/order-details/order-details';

// others
import DeliveryDetails from './components/other/delivery-details';
import CurrencyIcon from '../icons/Icons';
import Spinner from '../spinner/Spinner';
import Card from './components/card/card';
import { ArrowRightOnRectangle } from "@medusajs/icons";
import { forIn, set } from 'lodash';
import CreatingOrder from './components/creating-order/CreatingOrder';
import useScrollTop from '../../hooks/use-scroll-top';
import { validateString } from '../../helpers/validators';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const checkoutFlow = {
    CartUpdate: "cart-update",
    OrderSummary: "order-summary"
}

function Checkout() {
    const query = getQueryParams();
    const navigate = useNavigate();

    const {
        medusa,
        user,
        setUser,
        region,
        cart,
        setCart,
        setCartIdLocal
    } = useMedusa();

    const { stick, onClickHandler } = useScrollTop();

    const countriesOptions = (countries || []).map(country => ({ label: country.label, value: country.value.toLowerCase() }));

    const [loading, setLoading] = useState(false);
    const [loadingDis, setLoadingDis] = useState(false);
    const [checkoutStep, setCheckoutStep] = useState(checkoutFlow.CartUpdate);
    const [form, setForm] = useState(() => getInitialStateFormValues(null, countriesOptions));
    const [errors, setErrors] = useState(() => getInitialStateFormErrors());
    // This will be used to validate cart's shipping address & billing address just like a errors are made on submit but validation will run on selection of a address suggested by google places API.
    const [validation, setValidation] = useState(() => getInitialStateFormErrors());
    const [flagBillingAddress, setFlag] = useState(true);
    const [shippingOptions, setShippingOptions] = useState([]);
    const [apiError, setApiError] = useState("");
    const [createUserAccount, setCreateUserAccount] = useState(true);

    // useEffect(() => {
    //     console.log(form);
    // }, [form]);

    const countyOptions = useMemo(() => {
        if (form.shipping_address.country_code) {
            return countries
                .find(country => country.value.toLowerCase() === form.shipping_address.country_code.value)
                ?.states
                ?.map(state => ({
                    label: state.label,
                    value: state.value.toLowerCase()
                })) || [];
        } else {
            return [];
        }
    }, [form]);

    useEffect(() => {
        // set checkout state if values are there in cart
        if (
            cart &&
            cart.billing_address?.first_name &&
            cart.shipping_address?.first_name
        ) {
            setFlag(() => isSameAddress(cart.billing_address, cart.shipping_address));
        }
    }, [cart]);

    useEffect(() => {
        if (medusa && region && cart && shippingOptions.length <= 0) {
            fetchData();
        }
        if (user && !form['email']) {
            setForm((prevState) => ({
                ...prevState,
                email: user.email
            }));
        }
    }, [medusa, region, cart, user]);

    useEffect(() => {
        if (shippingOptions.length > 0 && !form.shipping_method) {
            const filteredShippingOptions = shippingOptions
                .filter((option) => option.metadata?.pickup !== "true")
            setForm((prevState) => ({
                ...prevState,
                shipping_method: filteredShippingOptions[0]
            }));
        }
    }, [shippingOptions])

    function fetchData() {
        if (shippingOptions.length <= 0) {
            // fetch shipping option
            axios.get(server_url + "/store/shipping-options?region_id=" + region.id)
                .then((response) => response.data)
                .then(({ shipping_options }) => {
                    // console.log(shipping_options);
                    // setShippingOptions(() => shipping_options);
                    setShippingOptions(() => filterShippingOptionsForCart({ cart, shipping_options }));
                })
        }
    }

    useEffect(() => {
        // if (
        //     cart &&
        //     cart.shipping_address.first_name &&
        //     cart.billing_address.first_name &&
        //     (cart.shipping_methods || []).length > 0
        // ) {
        //     // if cart is having shipping address, billing address and shipping_methods setCheckout step -> order summmary
        //     // setCheckoutStep(checkoutFlow.OrderSummary);
        // }
        setForm(() => getInitialStateFormValues(cart, countriesOptions));
    }, [cart]);

    function handleClickUserDetails() {
        setCheckoutStep(checkoutFlow.CartUpdate);
    }

    function handleClickDelivery() {
        setCheckoutStep(checkoutFlow.CartUpdate);
    }

    function handleClickOrder() {
        if (
            cart.shipping_address.first_name &&
            cart.billing_address.first_name &&
            cart.shipping_methods.length > 0
        ) {
            setCheckoutStep(checkoutFlow.OrderSummary);
        }
    }

    function scrollToComponent(component) {
        if (!component) return null;
        const elementRect = component.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.pageYOffset;
        const middle = absoluteElementTop - window.innerHeight / 2;

        window.scrollTo({
            top: middle,
            behavior: 'smooth', // Smooth scrolling behavior
        });
    }

    function getErrorComponents(parentClassName, childClassName) {
        const parentElement = document.querySelector(`.${parentClassName}`);
        if (!parentElement) {
            console.error(`Parent element with class "${parentClassName}" not found.`);
            return [];
        }

        const childComponents = parentElement.querySelectorAll(`.${childClassName}`);
        return childComponents;
    }

    useEffect(() => {
        if (medusa && cart && region && !loading) {
            // If payment successful create order
            if (user) {
                // If user is there
                if (
                    query &&
                    query?.["session_id"] &&
                    query?.["cart_id"] &&
                    cart.items?.length > 0
                    // medusaCart.id === query["cart_id"]
                ) {
                    createOrder();
                    setTimeout(() => {
                        const payButton = document.querySelector(".pay-button-primary");
                        if (payButton) {
                            scrollToComponent(payButton);
                        }
                    }, 200);
                }
            } else {
                // If user is not there ordering guest user
                if (
                    query &&
                    query?.["session_id"] &&
                    query?.["cart_id"]
                ) {
                    if (query["cart_id"] !== cart.id) {
                        fetchOrderingCart({ cart_id: query["cart_id"] });
                    } else {
                        createOrder();
                        setTimeout(() => {
                            const payButton = document.querySelector(".pay-button-primary");
                            if (payButton) {
                                scrollToComponent(payButton);
                            }
                        }, 200);
                    }
                }
            }
            // Handle if payment is unsucessful
            if (query && query?.["error"] === 'true' && cart?.items?.length > 0 && region) {
                handlePaymentNotMade();
            }
        }
    }, [medusa, query, cart, region])

    const fetchOrderingCart = async ({ cart_id }) => {
        return await medusa.carts.retrieve(cart_id)
            .then(({ cart: _c }) => {
                setCart(_c);
                setCartIdLocal(_c.id);
            });
    }

    const handleApplyDiscountCode = async (e) => {
        e.preventDefault();
        if (loadingDis) return;
        try {
            setLoadingDis(true);
            const discounts = form.discounts.map((code) => ({ code }));
            await medusa.carts.update(cart.id, {
                discounts
            })
                .then(({ cart: _c }) => {
                    // console.log(cart.id);
                    setCart(_c);
                    setForm((prevState) => ({
                        ...prevState,
                        discounts: [""]
                    }))
                })
        } catch (error) {
            console.error(error);
            cogoToast.error(
                "Invalid code!",
                { position: "bottom-left" }
            )
        } finally {
            setLoadingDis(false);
        }
    }

    const updateCart = async (e) => {
        e.preventDefault();
        let formData = form;
        let _cart = cart;

        // if all the products in the cart are collect use free delivery
        const allCollectProducts = (_cart?.items || []).filter((item) => item.metadata?.stock_location_id).length === (_cart?.items || []).length;

        if (allCollectProducts) {
            const shippingOptionPickup = shippingOptions.find((option) => option.metadata?.pickup === "true");
            if (shippingOptionPickup) {
                formData.shipping_method = shippingOptionPickup
            }
            formData.shipping_address = formData.billing_address
        }

        if (flagBillingAddress) {
            formData = {
                ...formData,
                billing_address: formData.shipping_address
            }
        }

        const _errors = validateFormData(formData, user);
        if (_errors) {
            setErrors(_errors);
            setTimeout(() => {
                const res = getErrorComponents("checkout-cart-form", "error");
                scrollToComponent(res[0]);
            }, 200);
            return;
        }
        setErrors(() => getInitialStateFormErrors());
        setValidation(() => getInitialStateFormErrors());


        if (createUserAccount && !user) {
            // call API to auto create user account
            await axios
                .post(
                    server_url + "/store/customers/create-random",
                    {
                        first_name: formData.shipping_address.first_name,
                        last_name: formData.shipping_address.last_name,
                        email: formData.email
                    }
                ).catch(err => {
                    cogoToast.error(
                        err.message,
                        { position: "bottom-left" }
                    )
                })
        }

        try {
            setLoading(true);

            let payload = getPayloadUpdateCart(formData);

            await medusa.carts.update(_cart.id, payload)
                .then(({ cart: _c }) => {
                    // console.log(cart.id);
                    setCart(_c);
                });

            await medusa.carts.addShippingMethod(_cart.id, {
                option_id: formData.shipping_method.id
            })
                .then(({ cart: _c }) => {
                    // console.log(cart.id);
                    setCart(_c);
                })

            setCheckoutStep(checkoutFlow.OrderSummary);
            onClickHandler();
        } catch (error) {
            console.error(error);
            cogoToast.error(error.message, { position: "bottom-left" });
        } finally {
            setLoading(false);
        }
    }

    // Handle click pay now 
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading) return;
        try {
            setApiError("");
            setLoading(true);

            const payload = {
                "line_items": cart.items.map(item => {
                    // In case of a Hire product
                    if (item.metadata?.hire_start) {
                        return ({
                            "price_data": {
                                "currency": cart.region.currency_code,
                                "product_data": {
                                    "name": item.title
                                },
                                "recurring": {
                                    "interval": "day",
                                    "interval_count": 30
                                },
                                "unit_amount": item.unit_price
                            },
                            "quantity": item.quantity
                        })
                    } else {
                        // In case of a standard product  
                        return ({
                            "price_data": {
                                "currency": cart.region.currency_code,
                                "product_data": {
                                    "name": item.title
                                },
                                "unit_amount": item.unit_price
                            },
                            "quantity": item.quantity
                        })
                    }
                }),
                "mode": cart.items.filter(item => item.metadata?.hire_start)?.length > 0 ? "subscription" : "payment",
                "metadata": {
                    "cart_id": cart.id
                }
            }
            if (cart.shipping_total > 0) {
                payload.line_items.push({
                    "price_data": {
                        "currency": cart.region.currency_code,
                        "product_data": {
                            "name": "Shipping charges"
                        },
                        "unit_amount": cart.shipping_methods[0].total
                    },
                    "quantity": 1
                })
            }

            const paymentSession = await axios
                .post(server_url + "/api/stripe/create-session", payload)
                .then(response => response.data);

            window.location.href = paymentSession.url
        } catch (error) {
            console.error(error);
            setApiError(error.message);
            cogoToast.error(
                error.message,
                { position: "bottom-left" }
            )
        } finally {
            setLoading(false);
        }
    }

    const createOrder = async () => {
        try {
            setApiError("");
            setLoading(true);
            setCheckoutStep(checkoutFlow.OrderSummary);
            const sessionId = query["session_id"];

            // validate payment
            const paymentValidated = await axios.get(`${server_url}/api/stripe/verify-session?session_id=${sessionId}`);
            if (!paymentValidated) {
                return navigate("/checkout");
            }

            /**
             * this is the cart against which order is created 
            */
            const cart_ordered = cart;

            // const paymentSessionCreated = await medusa.carts.createPaymentSessions(cart.id)
            //     .then(({ cart: _cart }) => {
            //         setCart(_cart);
            //         return _cart;
            //     });

            // if (!paymentSessionCreated) {
            //     cogoToast.error(
            //         "Payment session not created",
            //         { position: "bottom-left" }
            //     );
            //     return null;
            // }

            // const paymentProviderId = "stripe-shopism";
            // const paymentSessionSet = await medusa.carts.setPaymentSession(cart.id, {
            //     // retrieved from the payment session selected by the customer
            //     provider_id: paymentProviderId,
            // })
            //     .then(({ cart: _cart }) => {
            //         setCart(_cart);
            //         return _cart;
            //     });

            // if (!paymentSessionSet) {
            //     cogoToast.error(
            //         "Payment session not set",
            //         { position: "bottom-left" }
            //     );
            //     return null;
            // }

            // const order = await axios
            //     .post(`${server_url}/store/carts/complete/${cart.id}`)
            //     .then((response) => response.data)
            //     .then(({ type, data }) => {
            //         // navigate(`/order/${data.id}`);
            //         return data;
            //     })
            //     .catch(err => {
            //         return null;
            //     });

            // if (!order) {
            //     await medusa.carts.deletePaymentSession(cart.id, paymentProviderId)
            //         .then(({ cart: _cart }) => {
            //             setCart(_cart);
            //         })
            // }

            if (user) {
                await medusa.customers.update({
                    metadata: {
                        ...user?.metadata,
                        cart_id: "",
                    }
                })
                    .then(({ customer }) => {
                        setUser(customer);
                    })
            } else {
                await medusa.carts.create()
                    .then(({ cart: _c }) => {
                        setCart(() => ({ ..._c }));
                        setCartIdLocal(_c.id)
                        // console.log(cart.id);
                    })
            }

            // setForm(() => getInitialStateFormValues(null, countriesOptions));
            setErrors(() => getInitialStateFormErrors());
            setApiError("");
            cogoToast.success(
                "Order created successfully",
                { position: "bottom-left" }
            );
            navigate(
                user ?
                    `/order/order-thanks/${cart_ordered.id}` :
                    `/login-register/register?cart_id=${cart_ordered.id}`
            );

        } catch (error) {
            console.error(error);
            setApiError(error.message);
            cogoToast.error(
                error.message,
                { position: "bottom-left" }
            )
        } finally {
            setLoading(false);
        }
    }

    const handlePaymentNotMade = async () => {
        navigate("/checkout");
        setCheckoutStep(checkoutFlow.OrderSummary);
        const paymentProviderId = "stripe-shopism";
        await medusa.carts.deletePaymentSession(cart.id, paymentProviderId)
            .then(({ cart: _cart }) => {
                setCart(_cart);
            })
            .catch((error) => {
                cogoToast.error(
                    error.message,
                    { position: "bottom-left" }
                )
            });
        setApiError(`
        Payment Failed
We apologize, but there was an issue processing your payment. Please check your payment details and try again. If the problem persists, contact our customer support for assistance.
        `);
        cogoToast.error(
            "Payment Failed, Unable to create order.",
            { position: "bottom-left" }
        )
    }

    const validateAddress = async (_form) => {
        const validated = validateUnfilledAddressForm(_form);
        if (validated) {
            setErrors(() => getInitialStateFormErrors());
            setTimeout(() => {
                const res = getErrorComponents("checkout-cart-form", "validation");
                scrollToComponent(res[0]);
            }, 200);
            setValidation(validated);
        }
    }

    if (
        (cart?.items || []).length <= 0
    ) {
        return <></>;
    }

    // if (checkoutStep === checkoutFlow.OrderSummary && loading) {
    //     return <>
    //         <CreatingOrder />
    //     </>;
    // }

    return (
        <div className="checkout-area pt-25 pb-100">
            <div className="container">
                <div className='row'>
                    <div className='col-lg-7'>
                        <form onSubmit={updateCart} className='checkout-cart-form'>
                            <div className='pb-25'>
                                {(checkoutStep === checkoutFlow.CartUpdate) && (<Title
                                    number={1}
                                    title={
                                        (cart?.items || []).filter((item) => item.metadata?.stock_location_id).length !== (cart?.items || []).length ?
                                            "Delivery" : "Collection Information"
                                    }
                                    flag={Boolean(
                                        cart?.shipping_address?.first_name &&
                                        cart?.billing_address?.first_name &&
                                        (cart?.shipping_methods || []).length > 0
                                    )}
                                    onClick={handleClickDelivery}
                                />)}

                                {checkoutStep === checkoutFlow.CartUpdate ? <>
                                    {(!user) && (
                                        <Container className={'mb-20'}>
                                            <UserDetails
                                                form={form}
                                                setForm={setForm}
                                                errors={errors}
                                                createAccount={createUserAccount}
                                                setCreateAccount={setCreateUserAccount}
                                            />
                                        </Container>
                                    )}

                                    {(
                                        (cart?.items || []).filter((item) => item.metadata?.stock_location_id).length !== (cart?.items || []).length
                                    ) && (
                                            <Container
                                                className={'mb-20'}
                                            >
                                                <h3 className='mb-20'> Delivery Address </h3>
                                                <AddressForm
                                                    form={form}
                                                    setForm={setForm}
                                                    errors={errors}
                                                    countriesOptions={countriesOptions}
                                                    countyOptions={countyOptions}
                                                    validateAddress={validateAddress}
                                                    validation={validation}
                                                />
                                            </Container>
                                        )}

                                    {(
                                        (cart?.items || []).filter((item) => item.metadata?.stock_location_id).length > 0
                                    ) && (
                                            <Container
                                                className={'mb-20'}
                                            >
                                                <h3 className='mb-20'>
                                                    Collection Point
                                                </h3>
                                                <div>
                                                    <CollectLocation
                                                        cart={cart}
                                                    />
                                                </div>
                                            </Container>
                                        )}

                                    <Container
                                        className={'mb-20'}
                                    >
                                        <div className='d-flex flex-row align-items-center justify-content-between'>
                                            <h3 className=''>
                                                Billing Address
                                            </h3>
                                            {(
                                                (cart?.items || []).filter(item => item.metadata?.stock_location_id).length !== (cart?.items || []).length
                                            ) && (
                                                    <div className='d-flex flexx-row align-items-end'>
                                                        <span>
                                                            Same as Delivery Address
                                                        </span>
                                                        <Switch
                                                            value={flagBillingAddress}
                                                            onChange={(val) => setFlag(val)}
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                        {(
                                            !flagBillingAddress ||
                                            (cart?.items || []).filter(item => item.metadata?.stock_location_id).length === (cart?.items || []).length
                                        ) && (
                                                <div className='mt-20'>
                                                    <AddressForm
                                                        form={form}
                                                        setForm={setForm}
                                                        errors={errors}
                                                        countriesOptions={countriesOptions}
                                                        type="billing_address"
                                                        countyOptions={countyOptions}
                                                        validateAddress={validateAddress}
                                                        validation={validation}
                                                    />
                                                </div>
                                            )}
                                    </Container>

                                    {(
                                        (cart?.items || []).filter((item) => item.metadata?.stock_location_id).length !== (cart?.items || []).length
                                    ) && (
                                            <Container
                                                className={'mb-20'}
                                            >
                                                <h3 className='mb-20'>
                                                    Delivery Method
                                                </h3>
                                                <div>
                                                    <DeliveryMethod
                                                        shippingOptions={shippingOptions}
                                                        form={form}
                                                        setForm={setForm}
                                                        errors={errors}
                                                    />
                                                </div>
                                            </Container>
                                        )}

                                    <div className='d-flex flex-row justify-content-end my-3'>
                                        {!user && (
                                            <button
                                                className="btn-secondary btn-small d-flex justify-content-center align-items-center p-4 me-3"
                                                onClick={() => user ? null : setCheckoutStep(checkoutFlow.UserDetails)}
                                                type='button'
                                            >
                                                Back
                                            </button>)
                                        }
                                        <button
                                            className={`btn-primary btn-small d-flex justify-content-center align-items-center p-4`}
                                            type='submit'
                                        >
                                            {loading ? <Spinner /> : "Save & Continue"}
                                        </button>
                                    </div>
                                </> : (
                                    cart.shipping_address.first_name &&
                                    cart.billing_address.first_name
                                ) ? <>
                                    <Container
                                        className={'mb-20'}
                                    >
                                        <DeliveryDetails
                                            cart={cart}
                                            onClickEdit={() => setCheckoutStep(checkoutFlow.CartUpdate)}
                                        />
                                    </Container>
                                </> : <></>}
                            </div>
                        </form>

                        <div>
                            <Title
                                number={2}
                                title={"Order"}
                                flag={false}
                                onClick={handleClickOrder}
                            />
                            {(
                                checkoutStep === checkoutFlow.OrderSummary &&
                                (
                                    cart.shipping_address.first_name &&
                                    cart.billing_address.first_name
                                )
                            ) && (
                                    <form onSubmit={handleSubmit}>
                                        <Container>
                                            <OrderDetails cart={cart} />
                                        </Container>
                                        <div className='my-3'>
                                            <p className="text-danger small">{apiError}</p>
                                        </div>
                                        <div className='d-flex flex-row align-items-center justify-content-between'>
                                            <div className=''>
                                                <p className="fw-bolder"> Payment Issues? Please call 01 8614 050 </p>
                                            </div>
                                            <div className='d-flex flex-row justify-content-end'>
                                                <button
                                                    className="btn-secondary btn-small d-flex justify-content-center align-items-center p-4 me-3"
                                                    onClick={() => setCheckoutStep(checkoutFlow.CartUpdate)}
                                                    type='button'
                                                >
                                                    Back
                                                </button>
                                                <button
                                                    className={`pay-button btn-primary btn-small d-flex justify-content-center align-items-center p-4`}
                                                    type='submit'
                                                >
                                                    {loading ? <Spinner /> : "Proceed to Pay"}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                        </div>
                    </div>
                    <div className='col-lg-5'>
                        <div className="your-order-area">
                            {/* <h3>Your order</h3> */}
                            <Container>
                                <form
                                    className='d-flex flex-row align-items-center'
                                    onSubmit={handleApplyDiscountCode}
                                >
                                    <div className='col-lg-11'>
                                        <input
                                            type='text'
                                            name='discounts'
                                            placeholder='Discount Code'
                                            value={form["discounts"][0]}
                                            onChange={(e) => {
                                                const { name, value } = e.target;
                                                setForm((prevState) => ({
                                                    ...prevState,
                                                    discounts: [value]
                                                }));
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-1'>
                                        <button
                                            className='btn d-flex flex-row align-items-center px-1 justify-content-start'
                                            type='submit'
                                        // style={{
                                        //     border: "none",
                                        //     outline: "1px solid transparent",
                                        //     background: "none",
                                        //     transition: "all 0s"
                                        // }}
                                        >
                                            <span style={{ scale: "1.25" }}>
                                                <ArrowRightOnRectangle />
                                            </span>
                                            {loadingDis ? <span className='ms-2'><Spinner color='secondary' /></span> : ""}
                                        </button>
                                    </div>
                                </form>
                            </Container>
                            <div className="your-order-wrap gray-bg-4">
                                <div className="your-order-product-info">
                                    <div className="title-wrap">
                                        <h4 className="cart-bottom-title section-bg-gary-cart">
                                            Cart Total
                                        </h4>
                                    </div>

                                    {/* Add here  */}
                                    {<div className=" d-flex flex-row align-items-center justify-content-between mt-4">
                                        <div className=" col-lg-6 text-align-left">
                                            Standard Products
                                        </div>
                                        <div className=" col-lg-2">

                                        </div>
                                        <div className=" col-lg-1">
                                            {
                                                (cart?.items || [])
                                                    .filter((item) => !item.metadata?.hire_start && !item.metadata?.hire_end)
                                                    .reduce((acc, curr) => curr.quantity + acc, 0)
                                            }
                                        </div>
                                        <div className=" col-lg-2 d-flex flex-row justify-content-end">
                                            <CurrencyIcon />
                                            {
                                                (
                                                    (cart?.items || [])
                                                        .filter((item) => !item.metadata?.hire_start && !item.metadata?.hire_end)
                                                        .reduce((acc, curr) => curr.total + acc, 0) / 100
                                                ).toFixed(2)
                                            }
                                        </div>
                                    </div>}

                                    {((cart?.items || []).filter((item) => item.metadata?.hire_start && item.metadata?.hire_end).length > 0) && <div className=" d-flex mt-4 flex-row align-items-center justify-content-between">
                                        <div className=" col-lg-6 text-align-left">
                                            Recurring **Monthly Rental
                                        </div>
                                        <div className=" col-lg-2">

                                        </div>
                                        <div className=" col-lg-1">
                                            {
                                                (cart?.items || [])
                                                    .filter((item) => item.metadata?.hire_start && item.metadata?.hire_end)
                                                    .reduce((acc, curr) => curr.quantity + acc, 0)
                                            }
                                        </div>
                                        <div className=" col-lg-2 d-flex flex-row justify-content-end">
                                            <CurrencyIcon />
                                            {
                                                (
                                                    (cart?.items || [])
                                                        .filter((item) => item.metadata?.hire_start && item.metadata?.hire_end)
                                                        .reduce((acc, curr) => curr.total + acc, 0) / 100
                                                ).toFixed(2)
                                            }
                                        </div>
                                    </div>}

                                    {((cart?.items || []).filter((item) => item.metadata?.security_deposit).length > 0) && <div className=" d-flex mt-4  flex-row align-items-center justify-content-between">
                                        <div className=" col-lg-6 text-align-left">
                                            Refundable Deposit
                                        </div>
                                        <div className="col-lg-2">

                                        </div>
                                        <div className="col-lg-1">
                                            {
                                                (cart?.items || [])
                                                    .filter((item) => item.metadata?.security_deposit)
                                                    .reduce((acc, curr) => curr.quantity + acc, 0)
                                            }
                                        </div>
                                        <div className=" col-lg-2 d-flex flex-row justify-content-end">
                                            <CurrencyIcon />
                                            {
                                                (
                                                    (cart?.items || [])
                                                        .filter((item) => item.metadata?.security_deposit)
                                                        .reduce((acc, item) => {
                                                            return Number(item.metadata?.securityDeposit?.metadata?.[region?.id]?.amount)
                                                        }, 0)
                                                ).toFixed(2)
                                            }
                                        </div>
                                    </div>}

                                    <div className="d-flex mt-4 flex-row align-items-center justify-content-between">
                                        <div className=" col-lg-5 text-align-left">
                                            Shipping
                                        </div>
                                        <div className=" col-lg-2">
                                            {/* **Monthly */}
                                        </div>
                                        <div className=" col-lg-2">
                                        </div>
                                        <div className=" col-lg-2 d-flex flex-row justify-content-end">
                                            {cart?.shipping_methods?.length > 0 ? <Fragment>
                                                {!cart?.shipping_methods[0]?.total ? "Free" : <>
                                                    <CurrencyIcon />
                                                    {Number((cart.shipping_methods[0].total) / 100).toFixed(2)}
                                                </>}
                                            </Fragment> : form.shipping_method ? <>
                                                <CurrencyIcon />
                                                {Number(form.shipping_method?.amount / 100).toFixed(2)}
                                            </> : "-"}
                                        </div>
                                    </div>

                                    <div className=" d-flex  mt-4 flex-row align-items-center justify-content-between">
                                        <div className="border/ col-lg-6 text-align-left">
                                            VAT {"("}inclusive{")"}
                                        </div>
                                        <div className="border/ col-lg-2">

                                        </div>
                                        <div className="border/ col-lg-1">

                                        </div>
                                        <div className="border/ col-lg-2 d-flex flex-row justify-content-end">
                                            {"("}<CurrencyIcon /> {(cart?.tax_total / 100).toFixed(2)}{")"}
                                        </div>
                                    </div>

                                    <h4 className=" mt-4 grand-totall-title d-flex flex-row align-items-center justify-content-between">
                                        Grand Total{" "}
                                        <span>
                                            <span className="fw-bold fs-4">
                                                <CurrencyIcon />
                                            </span>
                                            <span className="fw-bold fs-4">
                                                {Number((cart?.total) / 100).toFixed(2)}
                                            </span>
                                        </span>
                                    </h4>

                                </div>
                                <div className="payment-method"></div>
                            </div>
                        </div>

                        <div className='my-4'>
                            {(
                                (cart?.items || []).filter((item) => item.metadata?.hire_start).length > 0 &&
                                (cart?.items || []).filter((item) => !item.metadata?.hire_start).length > 0
                            ) && (
                                    <p className="fw-bold">
                                        ** Note: Some of your items are marked for collection from your chosen collection point. Other items will be sent directly from this website to your address. No additional charges are incurred.
                                    </p>
                                )}
                        </div>

                        {(checkoutStep === checkoutFlow.OrderSummary) && <>
                            <button
                                className={`pay-button pay-button-primary btn-primary btn-small d-flex justify-content-center align-items-center p-4 w-100 mb-4`}
                                type='submit'
                                onClick={handleSubmit}
                                disabled={loading}
                            >
                                {loading ? <Spinner /> : "Proceed to Pay"}
                            </button>
                        </>}

                        <>
                            {(cart?.items || []).map((item, idx) =>
                                <Card key={idx} item={item} />
                            )}
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}

const getInitialStateFormValues = (
    cart,
    countries,
) => {
    return ({
        email: cart?.email || "",
        shipping_address: {
            company: cart?.shipping_address?.company || "",
            first_name: cart?.shipping_address?.first_name || "",
            last_name: cart?.shipping_address?.last_name || "",
            address_1: cart?.shipping_address?.address_1 || "",
            address_2: cart?.shipping_address?.address_2 || "",
            city: cart?.shipping_address?.city || "",
            country_code: countries.find(country => country.value === (cart?.shipping_address?.country_code || "ie")),
            province: cart?.shipping_address?.province || "",
            postal_code: cart?.shipping_address?.postal_code || "",
            phone: cart?.shipping_address?.phone || "",
            metadata: cart?.shipping_address?.metadata || {
                additional_info: "",
                county: null
            }
        },
        billing_address: {
            company: cart?.billing_address?.company || "",
            first_name: cart?.billing_address?.first_name || "",
            last_name: cart?.billing_address?.last_name || "",
            address_1: cart?.billing_address?.address_1 || "",
            address_2: cart?.billing_address?.address_2 || "",
            city: cart?.billing_address?.city || "",
            country_code: countries.find(country => country.value === (cart?.billing_address?.country_code || "ie")),
            province: cart?.billing_address?.province || "",
            postal_code: cart?.billing_address?.postal_code || "",
            phone: cart?.billing_address?.phone || "",
            metadata: cart?.billing_address?.metadata || {
                additional_info: "",
                county: null
            }
        },
        shipping_method: cart?.shipping_methods?.[0]?.shipping_option_id ? { id: cart?.shipping_methods?.[0]?.shipping_option_id } : "",
        discounts: [""]
    })
}

const getInitialStateFormErrors = () => {
    return ({
        email: [],
        shipping_address: {
            company: [],
            first_name: [], //
            last_name: [], //
            address_1: [], //
            address_2: [], //
            city: [],
            country_code: [],
            province: [], //
            postal_code: [], //
            phone: [], //
            metadata: {
                additional_info: [],
                county: []
            }
        },
        billing_address: {
            company: [],
            first_name: [], //
            last_name: [], //
            address_1: [], //
            address_2: [],
            city: [], //
            country_code: [],
            province: [], //
            postal_code: [], //
            phone: [], //
            metadata: {
                additional_info: [],
                county: []
            }
        },
        shipping_method: []
    })
}
const validatePhoneNumber = (input_str) => {
    const phoneRegex = /^[+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{1,4}[-\s\.]?[0-9]{1,6}$/im;
    return phoneRegex.test(input_str);
}

const validateFormData = (values, user) => {
    const errors = { ...getInitialStateFormErrors() };
    // Email
    if (!user && !values.email) {
        errors.email.push("Please enter your email.");
    }
    if (!user && !emailRegex.test(values.email)) {
        errors.email.push("Please enter a valid email address.");
    }

    // Shipping Address
    if (!values.shipping_address.first_name) {
        errors.shipping_address.first_name.push("Please enter your first name.");
    }
    if (
        values.shipping_address.first_name &&
        !validateString(values.shipping_address.first_name)
    ) {
        errors.shipping_address.first_name.push("Please enter a valid first name.");
    }
    if (!values.shipping_address.last_name) {
        errors.shipping_address.last_name.push("Please enter your last name.");
    }
    if (
        values.shipping_address.last_name &&
        !validateString(values.shipping_address.last_name)
    ) {
        errors.shipping_address.last_name.push("Please enter a valid last name.");
    }
    if (!values.shipping_address.address_1) {
        errors.shipping_address.address_1.push("Please enter your address.");
    }
    // if (!values.shipping_address.province) {
    //     errors.shipping_address.province.push("Please enter your region/state.");
    // }
    if (!values.shipping_address.country_code) {
        errors.shipping_address.country_code.push("Please select your country.");
    }
    if (values.shipping_address.country_code?.value !== "ie") {
        errors.shipping_address.country_code.push("We do not ship to your chosen country location. Please review.");
    }
    if (!values.shipping_address.phone) {
        errors.shipping_address.phone.push("Please enter your phone number.");
    }
    if (!validatePhoneNumber(values.shipping_address.phone)) {
        errors.shipping_address.phone.push("Please enter a valid phone number.");
    }
    if (!values.shipping_address.city) {
        errors.shipping_address.city.push("Please enter your city.");
    }
    if (!values.shipping_address.postal_code) {
        errors.shipping_address.postal_code.push("Please enter your postal code.");
    }
    if (!values.shipping_address.metadata.county?.label) {
        errors.shipping_address.metadata.county.push("Please enter your county.")
    }


    // Billing Address
    if (!values.billing_address.first_name) {
        errors.billing_address.first_name.push("Please enter your first name.");
    }
    if (!values.billing_address.last_name) {
        errors.billing_address.last_name.push("Please enter your last name.");
    }
    if (!values.billing_address.address_1) {
        errors.billing_address.address_1.push("Please enter your address.");
    }
    // if (!values.billing_address.province) {
    //     errors.billing_address.province.push("Please enter your region/state.");
    // }
    if (!values.billing_address.country_code) {
        errors.billing_address.country_code.push("Please select your country.");
    }
    if (values.billing_address.country_code?.value !== "ie") {
        errors.billing_address.country_code.push("We do not ship to your chosen country location. Please review.");
    }
    if (!values.billing_address.city) {
        errors.billing_address.city.push("Please enter your city");
    }
    if (values.billing_address.country_code?.value !== "ie") {
        errors.billing_address.country_code.push("We do not ship to your chosen country location. Please review.");
    }
    if (!values.billing_address.phone) {
        errors.billing_address.phone.push("Please enter your phone number.");
    }
    if (!validatePhoneNumber(values.billing_address.phone)) {
        errors.billing_address.phone.push("Please enter a valid phone number.");
    }
    if (!values.billing_address.postal_code) {
        errors.billing_address.postal_code.push("Please enter your postal code");
    }
    if (!values.billing_address.metadata.county?.label) {
        errors.billing_address.metadata.county.push("Please enter your county.")
    }

    if (!values.shipping_method.id) {
        errors.shipping_method.push("Please select a shipping method");
    }

    // console.log(errors);
    return (
        containsErrors(errors) ||
        containsErrors(errors.billing_address) ||
        containsErrors(errors.shipping_address) ||
        containsErrors(errors.billing_address.metadata) ||
        containsErrors(errors.shipping_address.metadata)
    ) ? errors : null;
}

export const validateUnfilledAddressForm = (values) => {
    const errors = { ...getInitialStateFormErrors() };

    // Shipping Address
    if (!values.shipping_address.first_name) {
        errors.shipping_address.first_name.push("Please enter your first name.");
    }
    if (!values.shipping_address.last_name) {
        errors.shipping_address.last_name.push("Please enter your last name.");
    }
    if (!values.shipping_address.address_1) {
        errors.shipping_address.address_1.push("Please enter your address.");
    }
    // if (!values.shipping_address.province) {
    //     errors.shipping_address.province.push("Please enter your region/state.");
    // }
    if (!values.shipping_address.country_code) {
        errors.shipping_address.country_code.push("Please select your country.");
    }
    if (values.shipping_address.country_code?.value !== "ie") {
        errors.shipping_address.country_code.push("We do not ship to your chosen country location. Please review.");
    }
    if (!values.shipping_address.phone) {
        errors.shipping_address.phone.push("Please enter your phone number.");
    }
    if (!validatePhoneNumber(values.shipping_address.phone)) {
        errors.shipping_address.phone.push("Please enter a valid phone number.");
    }
    if (!values.shipping_address.city) {
        errors.shipping_address.city.push("Please enter your city.");
    }
    if (!values.shipping_address.postal_code) {
        errors.shipping_address.postal_code.push("Please enter your postal code.");
    }
    if (!values.shipping_address.metadata.county?.label) {
        errors.shipping_address.metadata.county.push("Please enter your county.")
    }


    // Billing Address
    if (!values.billing_address.first_name) {
        errors.billing_address.first_name.push("Please enter your first name.");
    }
    if (!values.billing_address.last_name) {
        errors.billing_address.last_name.push("Please enter your last name.");
    }
    if (!values.billing_address.address_1) {
        errors.billing_address.address_1.push("Please enter your address.");
    }
    // if (!values.billing_address.province) {
    //     errors.billing_address.province.push("Please enter your region/state.");
    // }
    if (!values.billing_address.country_code) {
        errors.billing_address.country_code.push("Please select your country.");
    }
    if (values.billing_address.country_code?.value !== "ie") {
        errors.billing_address.country_code.push("We do not ship to your chosen country location. Please review.");
    }
    if (!values.billing_address.city) {
        errors.billing_address.city.push("Please enter your city");
    }
    if (values.billing_address.country_code?.value !== "ie") {
        errors.billing_address.country_code.push("We do not ship to your chosen country location. Please review.");
    }
    if (!values.billing_address.phone) {
        errors.billing_address.phone.push("Please enter your phone number.");
    }
    if (!validatePhoneNumber(values.billing_address.phone)) {
        errors.billing_address.phone.push("Please enter a valid phone number.");
    }
    if (!values.billing_address.postal_code) {
        errors.billing_address.postal_code.push("Please enter your postal code");
    }
    if (!values.billing_address.metadata.county?.label) {
        errors.billing_address.metadata.county.push("Please enter your county.")
    }
    return (
        containsErrors(errors) ||
        containsErrors(errors.billing_address) ||
        containsErrors(errors.shipping_address) ||
        containsErrors(errors.billing_address.metadata) ||
        containsErrors(errors.shipping_address.metadata)
    ) ? errors : null;
}

const getPayloadUpdateCart = (values) => {
    // flag denotes the billing_address is same as shipping_address
    return ({
        email: values.email,
        shipping_address: {
            ...values.shipping_address,
            country_code: values.shipping_address.country_code.value
        },
        billing_address: {
            ...values.billing_address,
            country_code: values.billing_address.country_code.value
        }
    })
}

export function isSameAddress(address_1, address_2) {
    if (!address_1 || !address_2) return false;
    const keysToKeep = [
        "first_name",
        "last_name",
        "address_1",
        "address_2",
        "city",
        "country_code",
        "province",
        "postal_code",
        // "phone"
    ]

    const keys_1 = Object.keys(address_1).filter(key => keysToKeep.includes(key));
    const keys_2 = Object.keys(address_2).filter(key => keysToKeep.includes(key));

    for (let key of keysToKeep) {
        if (address_1[key] !== address_2[key]) {
            return false;
        }
    }

    return true;
}

export const filterShippingOptionsForCart = ({
    cart,
    shipping_options
}) => {
    if (
        !cart || !shipping_options
    ) {
        return [];
    }

    const {
        region_id,
        subtotal
    } = cart;

    return shipping_options
        .filter((option) =>
            option["admin_only"] === false &&
            option["is_return"] === false &&
            option["region_id"] === region_id &&
            (
                subtotal >=
                (option["requirements"] || []).find((requirement) => requirement["type"] === "min_subtotal").amount &&
                subtotal <=
                (option["requirements"] || []).find((requirement) => requirement["type"] === "max_subtotal").amount
            )
        )
        .sort((a, b) => a["amount"] - b["amount"])
}

export default Checkout