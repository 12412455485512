import React, { Fragment, useEffect, useState } from 'react'
// import { useMedusa } from '../../../context/medusa/MedusaContext';
import axios from 'axios';
import { server_url } from '../../../environmentVariables/env';
import Spinner from '../../spinner/Spinner';

function CollectLocation({
    cart,
    handleSubmit,
    handleClickBack,
    isLoading
}) {
    // const { medusa } = useMedusa();

    const locationId = cart?.items?.find(item => item.metadata?.stock_location_id)?.metadata?.stock_location_id;

    const [location, setLocation] = useState(null);

    useEffect(() => {
        fetchLocation(locationId);
    }, [locationId])

    // useEffect(() => {
    //     console.log(location);
    // }, [location])

    const fetchLocation = async (location_id) => {
        if (!location_id) return null;
        try {
            return await axios.get(server_url + '/store/store-locations')
                .then(response => {
                    return response.data
                })
                .then(({ stock_locations }) => {
                    let location = stock_locations
                        .find(loc => loc.id === location_id);
                    setLocation(location);
                    return location;
                })
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <Fragment>
            {!location ? <div className='d-flex align-items-center justify-content-center py-5'>
                <Spinner color='text-gray-500' />
            </div> :
                <div className='d-flex flex-column collect-info'>
                    <h4 className=''> {location?.name || ""} </h4>
                    {location?.metadata?.icon_url && <div className='my-4'>
                        <img
                            className=''
                            style={{
                                maxHeight: "40px",
                                objectFit: "cover",
                            }}
                            src={location?.metadata?.icon_url || ""}
                        />
                    </div>}
                    <div className='my-2 d-flex flex-row justify-content-start flex-wrap location-info'>
                        <div
                            className='d-flex flex-column align-items-start justify-content-start py-2'
                        >
                            <p
                                style={{
                                    color: "var(--text-gray)",
                                }}
                            > Contact </p>
                            <p> {location?.address?.phone || ""} </p>
                        </div>
                        <div
                            className='d-flex flex-column align-items-start justify-content-start py-2'
                        >
                            <p
                                style={{
                                    color: "var(--text-gray)",
                                }}
                            > Address </p>
                            <p>

                                {location?.address?.address_1 ? location?.address?.address_1 + " " : ""}
                                {location?.address?.city ? location?.address?.city + " " : ""}
                                {location?.address?.country_code?.toUpperCase() ? location?.address?.country_code?.toUpperCase() + " " : ""}
                            </p>
                        </div>
                    </div>
                </div>}
        </Fragment>
    )
}

export default CollectLocation