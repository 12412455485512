import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCMS } from "../../context/CMS/cmsContext";
import { server_url } from "../../environmentVariables/env";
import { containsSubstring } from "../../helpers/containsSubstring";

const BlogSidebarDynamic = () => {
    const { blogs } = useCMS();
    const [searchedBlog, setSearchedBlog] = useState([]);
    const [tags, setTags] = useState([]);
    const [activeTags, setActiveTags] = useState([]);

    useEffect(() => {
        if (!blogs.length) return;

        let _tags = [];
        blogs.forEach(blog => {
            if (blog?.tags) {
                blog?.tags
                    .split(",")
                    .map(item => item.replace(/\s/g, ''))
                    .forEach(item => {
                        if (!_tags.includes(item)) _tags.push(item);
                    })
            }
        })
        setTags(() => _tags);

        return () => {
            setSearchedBlog([]);
            setTags([]);
            setActiveTags([]);
        }
    }, [blogs])

    // useEffect(() => {
    //     console.log(activeTags)
    // }, [activeTags])

    const handleChangeSearchInput = (e) => {
        const value = e.target.value;
        if (!value) return setSearchedBlog([]);
        const searchedRes = blogs.filter(blog =>
            containsSubstring(blog.blog_title, value) ||
            containsSubstring(blog.slug_url, value) ||
            containsSubstring(blog.template_description, value)
        );
        setSearchedBlog(searchedRes);
    }

    return (
        <div className="sidebar-style">
            <div className="sidebar-widget">
                <h4 className="pro-sidebar-title">Search </h4>
                <div className="pro-sidebar-search mb-55 mt-25">
                    <form className="pro-sidebar-search-form" action="#">
                        <input type="text" placeholder="Search here..." onChange={handleChangeSearchInput} />
                        {/* <button>
                            <i className="pe-7s-search" />
                        </button> */}
                    </form>
                    <div className="sidebar-project-wrap mt-30">
                        {searchedBlog.length > 0 && searchedBlog.map((blog, idx) => (
                            <div className="single-sidebar-blog" key={idx}>
                                <div className="sidebar-blog-img">
                                    <Link to={process.env.PUBLIC_URL + blog.slug_url}>
                                        <img
                                            src={
                                                server_url + blog?.featured_image.url
                                            }
                                            alt={blog?.slug_url}
                                        />
                                    </Link>
                                </div>
                                <div className="sidebar-blog-content">
                                    <span>Advice</span>
                                    <h4>
                                        <Link to={process.env.PUBLIC_URL + `/${blog?.slug_url}`}>
                                            {blog?.blog_title}
                                        </Link>
                                    </h4>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="sidebar-widget">
                <h4 className="pro-sidebar-title">Recent Articles </h4>
                <div className="sidebar-project-wrap mt-30">
                    {blogs.length > 0 && blogs.map((blog, idx) => (
                        <div className="single-sidebar-blog" key={idx}>
                            <div className="sidebar-blog-img">
                                <Link to={process.env.PUBLIC_URL + blog?.slug_url}>
                                    <img
                                        src={
                                            server_url + blog?.featured_image?.url
                                        }
                                        alt={blog?.slug_url}
                                    />
                                </Link>
                            </div>
                            <div className="sidebar-blog-content">
                                <span>Advice</span>
                                <h4>
                                    <Link to={process.env.PUBLIC_URL + `/${blog?.slug_url}`}>
                                        {blog?.blog_title}
                                    </Link>
                                </h4>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {tags.length > 0 && <div className="sidebar-widget mt-50">
                <h4 className="pro-sidebar-title">Tag </h4>
                <div className="sidebar-widget-tag mt-25">
                    <ul>
                        {tags.length > 0 && tags.map((tag, idx) => (
                            <li 
                                key={idx}
                                onClick={() => {
                                    let _updatedActiveTags = [];
                                    if (activeTags.includes(tag)) {
                                        _updatedActiveTags.filter(t => t !== tag)
                                    } else {
                                        _updatedActiveTags = [...activeTags, tag]
                                    }
                                    setActiveTags(() => _updatedActiveTags);
                                }}
                            >
                                {/* <Link to={process.env.PUBLIC_URL + "/blog-standard"}> */}
                                    Breastfeeding
                                {/* </Link> */}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>}
        </div>
    );
};

export default BlogSidebarDynamic;
