import { useEffect, useState } from 'react'
import { website_prefix } from '../environmentVariables/env';

const useLocalStorage = ( key, initialState ) => {
    const PREFIXED_KEY = website_prefix + key;

    const [value, setValue] = useState(() => {
        const data = localStorage.getItem(PREFIXED_KEY);
        if (data == null || typeof data === "undefined") return initialState != null ? initialState : null;
        else if (data != null || typeof data !== "undefined") return JSON.parse(data);
        else if (typeof initialState === "function") return initialState();
        else return null;
    })

    useEffect(() => {
        localStorage.setItem(PREFIXED_KEY, JSON.stringify(value));
    }, [value, PREFIXED_KEY]);

    return [value, setValue];
}

export default useLocalStorage;