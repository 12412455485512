import { createContext, useContext, useState, useEffect, useMemo } from "react";
import { isEqual } from "lodash";
import { isAvailableToBuy } from "../../helpers/isAvailableToBuy";
import cogoToast from "cogo-toast";
import { useMedusa } from "../medusa/MedusaContext";

const ProductContext = createContext();

export const useProduct = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
    const {
        cart
    } = useMedusa();

    const [product, setProduct] = useState(null);
    const [options, setOptions] = useState({});
    const [variants, setVariants] = useState([]);
    const [inStock, setInStock] = useState(true);
    const [quantity, setQuantity] = useState(1);
    const [maxQuantityMet, setMaxQuantityMet] = useState(false);

    useEffect(() => {
        if (!product) return;
        // initialize the option state
        const optionObj = {}
        for (const option of product.options) {
            Object.assign(optionObj, { [option.id]: undefined })
        }
        setOptions(optionObj)

        // initialize the option state
        setVariants(product?.variants);
    }, [product])

    // memoized record of the product's variants
    const variantRecord = useMemo(() => {
        if (!variants) return;
        const map = {};
        for (const variant of variants) {
            const tmp = {}
            for (const option of variant.options) {
                tmp[option.option_id] = option.value
            }
            map[variant.id] = tmp
        }
        return map;
    }, [variants]);

    // memoized function to check if the current options are a valid variant
    const variant = useMemo(() => {
        let variantId = undefined;
        for (const key of Object.keys(variantRecord)) {
            if (isEqual(variantRecord[key], options)) {
                variantId = key;
            }
        }
        const _variant = variants?.find((v) => v.id === variantId);

        // TO DO
        // If the selected variant is in the medusaCart set the quantity to the medusaCart's quantity | else set to 1
        if (cart && _variant) {
            const isCartItem = cart.items.find(item => item.variant_id === _variant.id);
            setQuantity(() => 1);
        }

        // return variants.find((v) => v.id === variantId);
        return _variant;
    }, [options, variantRecord, variants]);

    useEffect(() => {
        // console.log("CART UPDATED");
        if (cart) {
            const isCartItem = cart.items.find(item => item.variant_id === variant?.id);
            setQuantity(() => 1);
        }
    }, [cart])

    // Important 
    useEffect(() => {
        if (variants.length === 1) {
            setOptions(variantRecord[variants[0].id])
        } else if (variants.length >= 1) {
            setOptions(variantRecord[variants[0].id])
        }
    }, [variants, variantRecord])

    const disabled = useMemo(() => {
        return !variant
    }, [variant])

    const updateProduct = prod => {
        if (!prod) return;
        return setProduct(prod);
    }

    const updateOptions = newOp => {
        if (!newOp) return;
        setOptions({ ...options, ...newOp })
    }

    useEffect(() => {
        if (!variant) return;
        setInStock(() => {
            return isAvailableToBuy(variant);
        });
    }, [variant]);

    const increaseQuantity = () => {
        if (!variant) {
            return cogoToast.warn("Select a variant");
        }
        const maxQuantity = variant?.inventory_quantity || 0
        // console.log(maxQuantity);
        if (maxQuantity > quantity + 1) {
            setQuantity(quantity + 1)
        } else {
            setMaxQuantityMet(true);
            // dispatch a notification that max quantity reached.
            cogoToast.warn("Maximum quantity reached");
        }
    }

    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
            if (maxQuantityMet) {
                setMaxQuantityMet(false)
            }
        }
    }

    const updateQuantity = (qty) => {
        if (quantity > 1 && (quantity + 1 < variant?.inventory_quantity || 0)) {
            setQuantity(qty);
        }
        // cogoToast.warn("")
    }

    // TESTING
    // useEffect(() => {
    //     console.log("Variants/////////", variants)
    // }, [variants])

    // useEffect(() => {
    //     console.log("Product/////////", product)
    // }, [product])

    // useEffect(() => {
    //     console.log("Variant/////////", variant)
    // }, [variant])

    // useEffect(() => {
    //     console.log("//////////////", product?.id,  quantity);
    // }, [quantity])

    let value = {
        product,
        updateProduct,
        options,
        updateOptions,
        disabled,
        variant,
        variants,
        inStock,
        quantity,
        increaseQuantity,
        decreaseQuantity,
        updateQuantity
    }

    return (
        <ProductContext.Provider value={value}>
            {children}
        </ProductContext.Provider>
    )
}