import React, {
    useState,
    useEffect,
    Fragment,
    // useRef
} from 'react'
import { MagnifyingGlassMini } from "@medusajs/icons"
// import PlacesAutocomplete from 'react-places-autocomplete';
// import {
//     geocodeByAddress,
//     geocodeByPlaceId,
//     getLatLng,
// } from 'react-places-autocomplete';
// import PlacesAutocomplete, {
//     geocodeByAddress,
//     getLatLng,
// } from 'react-places-autocomplete';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import "./search-address.css";
import Spinner from '../../../spinner/Spinner';
// import axios from 'axios';
import { countries } from '../../../../data/Countries';
import { useMedusa } from '../../../../context/medusa/MedusaContext';

function AddressForm({
    form,
    setForm,
    errors,
    type = "shipping_address",
    countriesOptions,
    countyOptions,
    validateAddress,
    validation
}) {
    const {
        user
    } = useMedusa();

    function onChange(e) {
        const { name, value } = e.target;
        setForm((prevState) => ({
            ...prevState,
            [type]: {
                ...prevState[type],
                [name]: value
            }
        }))
    }

    // useEffect(() => {
    //     console.log(form);
    // }, [form]);

    // useEffect(() => {
    //     console.log(errors);
    // }, [errors])

    return (
        <Fragment>
            <div className="row">
                {(type === "billing_address" || user) ? (
                    <>
                        <div className="col-lg-6 col-md-6">
                            <div className="billing-info mb-20">
                                {/* <p className='fw-bold'>
                            First Name
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                                <i className="fa-solid fa-asterisk"></i>
                            </span>
                        </p> */}
                                <input
                                    value={form[type]["first_name"]}
                                    placeholder='First Name *'
                                    type="text"
                                    name='first_name'
                                    className={errors[type]["first_name"]?.[0] ? "error" : validation[type]["first_name"]?.[0] ? "validation" : ""}
                                    onChange={onChange}
                                    style={errors[type]["first_name"]?.[0] ? { border: "1px solid red" } : validation[type]["first_name"]?.[0] ? { border: "1px solid var(--validation-gree)" } : {}}
                                />
                                <div>
                                    {(errors[type]["first_name"]?.[0]) ? (
                                        <p className="text-danger small">
                                            {errors[type]["first_name"]?.[0] || ""}
                                        </p>
                                    ) : (
                                        <p className='small text-green-500'>
                                            {validation[type]["first_name"]?.[0] || ""}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="billing-info mb-20">
                                {/* <p className='fw-bold'>
                            Last Name
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                                <i className="fa-solid fa-asterisk"></i>
                            </span>
                        </p> */}
                                <input
                                    value={form[type]["last_name"]}
                                    type="text"
                                    placeholder='Last Name *'
                                    name='last_name'
                                    onChange={onChange}
                                    style={errors[type]["last_name"]?.[0] ? { border: "1px solid red" } : validation[type]["last_name"]?.[0] ? { border: "1px solid var(--validation-green)" } : {}}
                                    className={errors[type]["last_name"]?.[0] ? "error" : validation[type]["last_name"]?.[0] ? "validation" : ""}
                                />
                                <div>
                                    {(errors[type]["last_name"]?.[0]) ? (
                                        <p className="text-danger small">
                                            {errors[type]["last_name"]?.[0] || ""}
                                        </p>
                                    ) : (
                                        <p className='small text-green-500'>
                                            {validation[type]["last_name"]?.[0] || ""}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                ) : <></>}
                <div className='col-lg-12 col-md-12'>
                    <div className='mb-20'>
                        <LocationSearchInput
                            form={form}
                            setForm={setForm}
                            type={type}
                            countyOptions={countyOptions}
                            validateAddress={validateAddress}
                            validation={validation}
                        />
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="billing-info mb-20">
                        {/* <p className='fw-bold'>
                            Additional address information {"("}optional{")"}
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                            </span>
                        </p> */}
                        <input
                            className={`billing-address`}
                            placeholder="Additional information (optional)"
                            type="text"
                            name='additional_info'
                            value={form[type]["metadata"]["additional_info"]}
                            onChange={(e) => {
                                const { name, value } = e.target;
                                setForm((prevState) => ({
                                    ...prevState,
                                    [type]: {
                                        ...prevState[type],
                                        metadata: {
                                            ...prevState[type]["metadata"],
                                            [name]: value
                                        }
                                    }
                                }));
                            }}
                        />
                    </div>
                </div>
                {/* <div className="col-lg-12">
                    <div className="billing-info mb-20">
                        <p className='fw-bold'>
                            Address
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                                <i className="fa-solid fa-asterisk"></i>
                            </span>
                        </p>
                        <input
                            className={`billing-address ${errors[type]["address_1"]?.[0] ? "error" : ""}`}
                            placeholder="Address"
                            type="text"
                            name='address_1'
                            value={form[type]["address_1"]}
                            onChange={onChange}
                            style={errors[type]["address_1"]?.[0] ? { border: "1px solid red" } : {}}
                        />
                        <p className="text-danger small">{errors[type]["address_1"]?.[0] || ""}</p>
                    </div>
                </div> */}
                {/* <div className="col-lg-12">
                    <div className="billing-info mb-20">
                        <p className='fw-bold'>
                            Additional address information {"("}optional{")"}
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                            </span>
                        </p>
                        <input
                            className={`billing-address ${errors[type]["address_2"]?.[0] ? "error" : ""}`}
                            placeholder="Address Line 2"
                            // placeholder="Additional information (optional)"
                            type="text"
                            name='address_2'
                            value={form[type]["address_2"]}
                            onChange={onChange}
                            style={errors[type]["address_2"]?.[0] ? { border: "1px solid red" } : {}}
                        />
                        <p className="text-danger small">{errors[type]["address_2"]?.[0] || ""}</p>
                    </div>
                </div> */}

                <div className="col-lg-12">
                    <div className="billing-info mb-20">
                        {/* <p className='fw-bold'>
                            Town / City
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                                <i className="fa-solid fa-asterisk"></i>
                            </span>
                        </p> */}
                        <input
                            value={form[type]["city"]}
                            type="text"
                            placeholder='Town / City *'
                            name='city'
                            onChange={onChange}
                            style={errors[type]["city"]?.[0] ? { border: "1px solid red" } : validation[type]["city"]?.[0] ? { border: "1px solid var(--validation-green)" } : {}}
                            className={errors[type]["city"]?.[0] ? "error" : validation[type]["city"]?.[0] ? "validation" : ""}
                        />
                        <div>
                            {(errors[type]["city"]?.[0]) ? (
                                <p className="text-danger small">
                                    {errors[type]["city"]?.[0] || ''}
                                </p>
                            ) : (
                                <p className='small text-green-500'>
                                    {validation[type]["city"]?.[0] || ""}
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                {/* <div className="col-lg-12">
                    <div className="billing-info mb-20">
                        <p className='fw-bold'>
                            Region / State
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                                <i className="fa-solid fa-asterisk"></i>
                            </span>
                        </p>
                        <input
                            value={form[type]["province"]}
                            type="text"
                            placeholder='Province'
                            name='province'
                            onChange={onChange}
                            style={errors[type]["province"]?.[0] ? { border: "1px solid red" } : {}}
                            className={errors[type]["province"]?.[0] ? "error" : ""}
                        />
                        <p className="text-danger small">{errors[type]["province"]?.[0] || ''}</p>
                    </div>
                </div> */}

                <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-20">
                        {/* <p className='fw-bold'>
                            Postal code / ZIP
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                                <i className="fa-solid fa-asterisk"></i>
                            </span>
                        </p> */}
                        <input
                            type="text"
                            value={form[type]["postal_code"]}
                            placeholder='Postal Code *'
                            name="postal_code"
                            onChange={onChange}
                            style={errors[type]["postal_code"]?.[0] ? { border: "1px solid red" } : validation[type]["postal_code"]?.[0] ? { border: "1px solid var(--validation-green)" } : {}}
                            className={errors[type]["postal_code"]?.[0] ? "error" : validation[type]["postal_code"]?.[0] ? "validation" : ""}
                        />
                        <div>
                            {(errors[type]["postal_code"]?.[0]) ? (
                                <p className="text-danger small">
                                    {errors[type]["postal_code"]?.[0] || ""}
                                </p>
                            ) : (
                                <p className='small text-green-500'>
                                    {validation[type]["postal_code"]?.[0] || ""}
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-20">
                        {/* <p className='fw-bold'>
                            Phone
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                                <i className="fa-solid fa-asterisk"></i>
                            </span>
                        </p> */}
                        <input
                            value={form[type]["phone"]}
                            type="tel"
                            placeholder='Phone *'
                            name='phone'
                            onChange={onChange}
                            className={errors[type]["phone"]?.[0] ? "error" : validation[type]["phone"]?.[0] ? "validation" : ""}
                            style={errors[type]["phone"]?.[0] ? { border: "1px solid red" } : validation[type]["phone"]?.[0] ? { border: "1px solid var(--validation-green)" } : {}}
                        />
                        <div>
                            {(errors[type]["phone"]?.[0]) ? (
                                <p className="text-danger small">
                                    {errors[type]["phone"]?.[0] || ""}
                                </p>
                            ) : (
                                <p className='small text-green-500'>
                                    {validation[type]["phone"]?.[0] || ""}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="billing-select mb-20">
                        {/* <p className='fw-bold'>
                            Country
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                                <i className="fa-solid fa-asterisk"></i>
                            </span>
                        </p> */}
                        <select
                            placeholder='County *'
                            value={form[type]["metadata"]["county"]?.label}
                            name='county'
                            className={`react-select ${errors[type]["metadata"]["county"]?.[0] ? 'error' : validation[type]["metadata"]["county"]?.[0] ? "validated" : ''}`}
                            // className=''
                            style={errors[type]["metadata"]["county"]?.[0] ?
                                {
                                    border: "1px solid red",
                                    // padding: "12px 8px",
                                } :
                                validation[type]["metadata"]["county"]?.[0] ? {
                                    border: "1px solid var(--validation-green)"
                                } :
                                    {
                                        // padding: "12px 8px",
                                        // border: "1px solid #eceff8"
                                    }}
                            onChange={(e) => {
                                const { name, value } = e.target;
                                // console.log(name, value);
                                const selectedOption = countyOptions.find(op => op.label === value);
                                setForm((prevState) => ({
                                    ...prevState,
                                    [type]: {
                                        ...prevState[type],
                                        ["metadata"]: {
                                            ...prevState[type]["metadata"],
                                            [name]: selectedOption
                                        }
                                    }
                                }))
                            }}
                        >
                            <option>Select a County *</option>
                            {countyOptions.map((el, idx) => (
                                <option
                                    key={idx}
                                >
                                    {el.label}
                                </option>
                            ))}
                        </select>
                        <div>
                            {(errors[type]["metadata"]["county"]?.[0]) ? (
                                <p className="text-danger small">
                                    {errors[type]["metadata"]["county"]?.[0] || ""}
                                </p>
                            ) : (
                                <p className='small text-green-500'>
                                    {validation[type]["metadata"]["county"]?.[0] || ""}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="billing-select mb-20">
                        {/* <p className='fw-bold'>
                            Country
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                                <i className="fa-solid fa-asterisk"></i>
                            </span>
                        </p> */}
                        <select
                            placeholder='Country *'
                            value={form[type]["country_code"]?.label}
                            name='country_code'
                            className={`react-select ${errors[type]["country_code"]?.[0] ? "error" : validation[type]["country_code"]?.[0] ? "validation" : ""}`}
                            style={errors[type]["country_code"]?.[0] ?
                                {
                                    border: "1px solid red",
                                } :
                                validation[type]["country_code"]?.[0] ? {
                                    border: "1px solid var(--validation-green)"
                                } :
                                    {}
                            }
                            onChange={(e) => {
                                const { name, value } = e.target;
                                const selectedOption = countriesOptions.find(op => op.label === value);
                                setForm((prevState) => ({
                                    ...prevState,
                                    [type]: {
                                        ...prevState[type],
                                        [name]: selectedOption
                                    }
                                }))
                            }}
                        >
                            <option>Select a Country *</option>
                            {countriesOptions.map((el, idx) => (
                                <option
                                    style={{ padding: "20px" }} // Adjusted height
                                    key={idx}
                                >
                                    {el.label}
                                </option>
                            ))}
                        </select>
                        <div>
                            {(errors[type]["country_code"]?.[0]) ? (
                                <p className="text-danger small">
                                    {errors[type]["country_code"]?.[0] || ""}
                                </p>
                            ) : (
                                <p className='small text-green-500'>
                                    {validation[type]["country_code"]?.[0] || ""}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AddressForm


const LocationSearchInput = ({
    form,
    setForm,
    type,
    countyOptions,
    validateAddress,
    validation
}) => {
    const [address, setAddress] = useState('');

    const handleChange = (address) => {
        setForm((prevState) => ({
            ...prevState,
            [type]: {
                ...prevState[type],
                "address_1": address
            }
        }));
    };

    const handleSelect = (address) => {
        geocodeByAddress(address)
            .then(results => {
                getAddressObject(results[0])
                return results;
            })
            .then(results => getLatLng(results[0]))
            // .then(latLng => console.log('Success', latLng))
            .catch(error => console.error('Error', error));
    };

    // Assuming you have the response object stored in a variable named 'response'


    function getAddressObject(result) {
        const addressComponents = result.address_components;
        // console.log(addressComponents);
        let addressObj = {
            "street_number": "",
            "route": "",
            "locality": "",
            "neighborhood": "",
            "administrative_area_level_1": "",
            "postal_code": "",
            "county_code": "",
        }

        let addressString = "";
        for (const component of addressComponents) {
            // console.log(addressComponents);
            const type = component.types[0];
            switch (type) {
                case "street_number":
                    addressObj.street_number = component.long_name;
                    break;
                case "route":
                    addressObj.route = component.long_name;
                    break;
                case "locality":
                    addressObj.locality = component.long_name;
                    break;
                case "administrative_area_level_1": // State
                    addressObj.administrative_area_level_1 = component.long_name;
                    addressObj.county_code = (component?.short_name || "").toLowerCase();
                    break;
                case "postal_code":
                    addressObj.postal_code = component.long_name;
                    break;
                case "neighborhood": // Neighborhood
                    addressObj.neighborhood = component.long_name;
                    break;
                // Add cases for other address component types as needed
            }
        }

        addressComponents.forEach(component => {
            if (component.types.includes('sublocality_level_1') || component.types.includes('neighborhood')) {
                //   neighborhood = component.long_name;
                addressObj.neighborhood = component.long_name;
            }
            if (component.types.includes('locality')) {
                addressObj.locality = component.long_name;
                //   locality = component.long_name;
            }
        });

        const _form = {
            ...form,
            [type]: {
                ...form[type],
                address_1: addressObj.street_number + " " + addressObj.route,
                city:
                    addressObj.neighborhood || addressObj.locality ||
                    filterKeysFromString(
                        addressObj.administrative_area_level_1,
                        ["county"]
                    ) ||
                    "",
                postal_code: addressObj.postal_code,
                metadata: {
                    ...form[type]["metadata"],
                    county: countyOptions.find(option => option.value === addressObj.county_code),
                }
            }
        }

        setForm(_form);

        const searchInput = document.querySelector(".location-search-input");
        if (searchInput) {
            searchInput.value = addressObj.street_number + ' ' + addressObj.route;
        }

        validateAddress(_form);
        // console.log("Extracted Address:", addressString, addressObj);
    }

    const filterKeysFromString = (inputString, keysArray) => {
        const words = inputString.split(' ');
        return words.filter(word => !keysArray.includes(word.toLowerCase())).join(' ');
    }

    const searchOptions = {
        componentRestrictions: { country: 'ie' }, // Ireland's ISO code
        types: ['address'] // Search for addresses only
    };

    return (
        <PlacesAutocomplete
            value={form[type]["address_1"]}
            onChange={handleChange}
            onSelect={handleSelect}
            debounce={1000}
            searchOptions={searchOptions}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className='places-suggest-dropdown-area'>
                    <input
                        value={form[type]['address_1']}
                        name='address_1'
                        type='text'
                        className='location-search-input'
                        onChange={(e) => {
                            const { name, value } = e.target;
                            console.log(name, value)
                            setForm((prevState) => ({
                                ...prevState,
                                [type]: {
                                    ...prevState[type],
                                    [name]: value
                                }
                            }));
                        }}
                        {...getInputProps({
                            placeholder: 'Street Address *',
                            className: 'location-search-input',
                            name: "address_1",
                            type: "text"
                        })}
                    />
                    <div className="autocomplete-dropdown-container">
                        {loading && <div className='py-1 d-flex flex-row align-items-center justify-content-start'>
                            <Spinner color='secondary' />
                        </div>}
                        {suggestions.map((suggestion, key) => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                                <div
                                    key={key}
                                    {...getSuggestionItemProps(suggestion, {
                                        className: `d-flex flex-row align-items-center py-3 px-2 ${className}`,
                                        style,
                                    })}
                                >
                                    <span>
                                        <i className="fa-solid fa-location-dot me-2 text-secondary"></i>
                                        <>
                                            {suggestion.description}
                                        </>
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
};

// export default LocationSearchInput;
