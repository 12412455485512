import PropTypes from "prop-types";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import cogoToast from "cogo-toast";
import { containsErrors } from "../../../helpers/containsErrors";
import { useState } from "react";
import axios from "axios";
import { server_url } from "../../../environmentVariables/env";
import Spinner from "../../spinner/Spinner";
import { useMedusa } from "../../../context/medusa/MedusaContext";
import { useNavigate } from "react-router-dom";
import QueryString from "qs";

const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

const initialStateFormValues = {
  email: "",

}

const initialStateFormErrors = {
  email: [],
  preferences: []
}

const validateFormData = (formValues) => {
  const errors = {
    email: [],
    preferences: []
  }
  if (!formValues.email) {
    errors.email.push("Enter email address")
  }
  if (!emailRegex.test(formValues.email)) {
    errors.email.push("Enter a valid email address")
  }
  return containsErrors(errors) ? errors : null;
}

const CustomForm = ({ status, message, onValidated }) => {
  const { user } = useMedusa();
  const navigate = useNavigate();

  const [formData, setFormData] = useState(() => ({ ...initialStateFormValues }));
  const [formErrors, setFormErrors] = useState(() => ({ ...initialStateFormErrors }));
  const [isLoading, setIsLoading] = useState(false);

  // let email;
  // const submit = () => {
  //   email &&
  //     email.value.indexOf("@") > -1 &&
  //     onValidated({
  //       EMAIL: email.value
  //     });

  //   let emailInput = document.getElementById("mc-form-email");
  //   emailInput.value = "";
  // };

  const submit = async () => {
    if (!user) {
      navigate(`/login-register/login`)
      return;
    }
    const errors = validateFormData(formData);
    if (errors) {
      setFormErrors(errors);
      return;
    }
    setIsLoading(true);

    let payload = { ...formData }
    try {
      const stringifiedQuery = QueryString.stringify(
        {
          where: {
            name: {
              equals: "newsletter_subscribers"
            }
          },
        },
        { addQueryPrefix: true },
      )
      const emailListNewsletter = await axios
        .get(`${server_url}/api/emailList${stringifiedQuery}`)
        .then(response => response.data.docs.length > 0 ? response.data.docs[0] : null)

      if (emailListNewsletter) {
        const { id } = emailListNewsletter;
        const payload = { email_id: formData.email, email_list_id: id };
        await axios.post(`${server_url}/api/emailsRepo`, payload);
      }

      cogoToast.success("Newsletter Subscribed!");
      setFormErrors(() => ({ ...initialStateFormErrors }));
      setFormData(() => ({ ...initialStateFormValues }));
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="subscribe-form">
      <div className="mc-form">
        <div>
          <input
            id="mc-form-email"
            className="email bg-white px-3 border"
            // ref={node => (email = node)}
            type="email"
            placeholder="Enter your email address..."
            value={formData.email}
            onChange={(e) => {
              setFormData(prevState => ({
                ...prevState,
                email: e.target.value
              }))
            }}
          />
          <p className="text-danger">{formErrors.email.length > 0 && formErrors.email[0]}</p>
        </div>
        <div className="clear">
          <button className="button" onClick={submit} style={{ minWidth: "70px" }}>
            {isLoading ? <Spinner color="darkgray" /> : "SUBSCRIBE"}
          </button>
        </div>
      </div>

      {status === "sending" && (
        <div style={{ color: "#3498db", fontSize: "12px" }}>sending...</div>
      )}
      {status === "error" && (
        <div
          style={{ color: "#e74c3c", fontSize: "12px" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "#2ecc71", fontSize: "12px" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </div>
  );
};

const SubscribeEmail = ({ mailchimpUrl }) => {
  return (
    <div>
      <MailchimpSubscribe
        url={mailchimpUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

SubscribeEmail.propTypes = {
  mailchimpUrl: PropTypes.string
};

export default SubscribeEmail;
